/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import { useAuthStore, useBrokerageStore } from '@modules/core/store';
import useKYCData from '@modules/kyc/hooks/queries/useKYCData';
import {
  KnowYourCustomer,
  FormOperationsProvider,
  initialFormValuesMapper,
  kycSchemaResolver,
  vaultFormUtils
} from '@huspy/forge/features';
import {
  KYCApplicant,
  EmploymentKYCData,
  KYCCountry,
  KYCData,
} from '@huspy/forge/types';
import {
  useVaultFormContext,
  useVaultsForm,
  VaultFormProvider,
  VaultFormValues,
} from '@modules/core/pages/Home/form-context';
import React, {
  useEffect, useMemo, useState
} from 'react';
import useVaultData from '@modules/documents/hooks/queries/useVaultData';
import { Applicant as ApplicantDocuments } from '@modules/documents/api/types';
import { BulkUploadWrapper } from '@modules/documents/components/BulkUpload';
import { Box } from '@huspy/briks-web';
import useVaultProgress from '@modules/core/hooks/queries/useVaultProgress';
import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';
import useUpdateKYCData from '@modules/kyc/hooks/mutations/updateKyc';
import { scrollToError } from '@shared/utils';
import { useTranslation } from 'react-i18next';
import { useClientFormErrorHandler } from '@modules/kyc/hooks/useHandleError';
import {
  resetAmplitudeIdentity,
  trackAmplitudeEvent,
  updateAmplitudeUserProperties,
} from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import useGetOpportunityId from '@modules/core/hooks/useGetOpportunityId';
import { useNavigate } from 'react-router-dom';
import useGetBanks from '@modules/kyc/hooks/queries/useGetBanks';
import { useDisclosure } from '@mantine/hooks';
import { useGetVaultStorage } from '@modules/kyc/hooks/queries/useGetVaultStorage';
import { useAutoSave } from '@modules/core/hooks/useAutoSave';
import { useSubmitVault } from '@modules/kyc/hooks/mutations/submitVault';
import { CITIZEN_STATUS } from '@modules/kyc/api/const';
import { VAULT_TAB, VaultTab } from './types';
import {
  VaultAppShell,
  VaultAppShellFallback,
  Header,
  MortgageTimeline,
} from './widgets';
import { SubmitSlido } from './widgets/SubmitSlido';

function convertToApplicantType(data: KYCData): KYCApplicant[] {
  return Object.entries(data).map(([applicantId, applicantData]) => ({
    ...applicantData,
    applicant_id: applicantId,
  }));
}

const FormsTab = React.memo(
  ({
    applicantsKYC,
    isSpainBusiness,
    isVaultSubmitted,
    isVaultDisabled,
  }: {
    applicantsKYC: KYCApplicant<KYCCountry>[];
    isSpainBusiness: boolean;
    isVaultSubmitted: boolean;
    isVaultDisabled: boolean;
  }) => (
    <>
      {applicantsKYC.map((applicant, idx) => (
        <Box key={ applicant.applicant_id } my='4'>
          <Header
            firstName={ applicant.personal_information.first_name }
            lastName={ applicant.personal_information.last_name }
            type={ applicant.applicant_type }
            isVaultSubmitted={ isVaultSubmitted }
            isVaultDisabled={ isVaultDisabled }
          />
          <KnowYourCustomer
            key={ applicant.applicant_id }
            formKey={ `applicants.${idx}` }
            applicant={ applicant }
            isSpainBusiness={ isSpainBusiness }
          />
        </Box>
      ))}
    </>
  )
);

const DocumentsTab = React.memo(
  ({
    applicantsDocuments,
    opportunityId,
  }: {
    applicantsDocuments: ApplicantDocuments[];
    opportunityId: string;
  }) => (
    <>
      {applicantsDocuments.map((applicant) => (
        <Box key={ applicant.applicant_id } my='4'>
          <Header
            firstName={ applicant.first_name }
            lastName={ applicant.last_name }
            type={ applicant.applicant_type }
          />
          <BulkUploadWrapper
            key={ applicant.applicant_id }
            oppId={ opportunityId }
            applicantId={ applicant.applicant_id }
            documents={ applicant.required_documents }
            isSimplified
          />
        </Box>
      ))}
    </>
  )
);

const getInitialValues = (
  applicantsKYC: KYCApplicant<KYCCountry>[],
  isSpainBusiness: boolean
) => {
  const {
    employmentAE,
    employmentES,
    personal,
    residency,
    liabilities,
    incomeAE,
    incomeEs,
  } = initialFormValuesMapper;

  return {
    applicants: applicantsKYC.map((applicant) => ({
      applicantId: applicant.applicant_id,
      applicantType: applicant.applicant_type,
      personal_information: personal.getInitialValues(
        applicant.personal_information
      ),
      residential_information: residency.getInitialValues(
        applicant.residential_information
      ),
      incomes: isSpainBusiness
        ? incomeEs.getInitialValues(applicant.financial_information.incomes)
        : incomeAE.getInitialValues(applicant.financial_information.incomes),
      employment_information: isSpainBusiness
        ? employmentES.getInitialValues(
          applicant.employment_information as EmploymentKYCData<'ES'>
        )
        : employmentAE.getInitialValues(
          applicant.employment_information as EmploymentKYCData<'AE'>
        ),
      ...liabilities.getInitialValues(
        applicant.financial_information.liabilities
      ),
    })),
  };
};

const Form = ({
  opportunityId,
  applicantsKYC,
  applicantsDocuments,
  isSpainBusiness,
  formDescription,
  isFormCompleted,
  isVaultSubmitted,
  isVaultDisabled,
}: {
  opportunityId: string;
  applicantsKYC: KYCApplicant<KYCCountry>[];
  applicantsDocuments: ApplicantDocuments[];
  formDescription: string;
  isFormCompleted: boolean;
  isSpainBusiness: boolean;
  isVaultSubmitted: boolean;
  isVaultDisabled: boolean;
}) => {
  const { t } = useTranslation();
  const { data: brokerage } = useBrokerageStore();
  const { getClientId, getIsAutoSave } = useAuthStore();
  const isAutoSaveOn = getIsAutoSave();
  const initialValues = getInitialValues(applicantsKYC, isSpainBusiness);
  const form = useVaultsForm({
    mode: 'uncontrolled',
    initialValues,
    validate: kycSchemaResolver(isSpainBusiness),
  });

  const memoizedForm = useMemo(() => form, [JSON.stringify(form.errors)]);

  const [activeVaultTab, setActiveVaultTab] = useState<VaultTab>(
    isVaultSubmitted && !isSpainBusiness ? VAULT_TAB.MORTGAGE_TIMELINE : VAULT_TAB.FORMS
  );
  const isFormsTabActive = activeVaultTab === VAULT_TAB.FORMS;
  const isDocumentsTabActive = activeVaultTab === VAULT_TAB.DOCUMENTS;
  const isMortgageTimelineTabActive = activeVaultTab === VAULT_TAB.MORTGAGE_TIMELINE;
  const {
    mutateAsync: updateKycMutate,
    isPending: updateKycIsPending,
    error: updateKycErrors,
  } = useUpdateKYCData<KYCCountry>(opportunityId);
  const {
    mutateAsync: submitMutateAsync,
    isPending: submitIsPending,
    error: submitErrors,
  } = useSubmitVault<KYCCountry>(opportunityId);
  const [isOpen, { close, open }] = useDisclosure(false);
  const isResident = applicantsKYC[0]?.personal_information.citizen_status
    !== CITIZEN_STATUS.nonResident;
  const { calculateCompletedFields, getTotalFieldsCount, getMissingFields } = vaultFormUtils;
  const [fieldsCount, setFieldsCount] = useState({
    total: getTotalFieldsCount(
      applicantsKYC.length,
      isSpainBusiness,
      isResident
    ),
    count: calculateCompletedFields(
      form.getValues() as VaultFormValues<'AE'>,
      isSpainBusiness,
      isResident
    ),
  });
  const updateFieldsCount = (formValues: VaultFormValues<'AE'>) => {
    setFieldsCount({
      ...fieldsCount,
      count: calculateCompletedFields(formValues, isSpainBusiness, isResident),
    });
  };
  const { isAutoSaveLoading, autoSaveHasError } = useAutoSave(
    form,
    opportunityId,
    updateFieldsCount,
    isVaultSubmitted || isVaultDisabled
  );

  const updatedFormDescription = isAutoSaveOn
    ? t('home.mandatoryFields', {
      count: fieldsCount.count,
      total: fieldsCount.total,
    })
    : formDescription;

  const isFormCompletedUpdated = isAutoSaveOn ? (fieldsCount.count / fieldsCount.total === 1) : isFormCompleted;

  const { data: banksData } = useGetBanks();

  const banks = useMemo(
    () =>
      banksData?.map((bank) => ({
        label: bank.title,
        value: bank.title,
      })),
    [banksData]
  );

  const isFormValid = () => {
    const { errors, hasErrors } = form.validate();

    if (hasErrors) {
      trackAmplitudeEvent(
        USER_EVENTS.FORM_FIELDS.VAULT_FIELDS_VALIDATION_RAISED,
        {
          client_id: getClientId(),
          brokerage_external_id: brokerage?.external_id,
          brokerage_name: brokerage?.name,
          case_id: opportunityId,
          error: errors,
        }
      );
      close();
      scrollToError(errors);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const data = form.getValues();
    try {
      const missingFields = getMissingFields(data, isSpainBusiness, isResident);
      await (isAutoSaveOn ? submitMutateAsync({ data, missingFields }) : updateKycMutate(data));
      form.resetDirty();
    } catch (error_) {
      console.error(error_);
    }
  };

  const serverErrors = isAutoSaveOn ? submitErrors : updateKycErrors;

  useClientFormErrorHandler({ clientErrors: serverErrors, form });

  useEffect(() => {
    form.setValues(initialValues);
  }, [JSON.stringify(applicantsKYC)]);

  const isLoading = updateKycIsPending || submitIsPending;
  return (
    <VaultFormProvider form={ memoizedForm }>
      <FormOperationsProvider
        operations={ {
          useVaultFormContext,
          banks,
          isAllFieldDisabled: isVaultSubmitted || isVaultDisabled,
        } }
      >
        <form
          onSubmit={ async (e) => {
            e.preventDefault();
            if (!isFormValid()) {
              return;
            }
            if (isAutoSaveOn) {
              open();
              return;
            }
            handleSubmit();
          } }
        >
          <VaultAppShell
            activeVaultTab={ activeVaultTab }
            setActiveVaultTab={ setActiveVaultTab }
            isAutoSaveLoading={ isAutoSaveLoading }
            autoSaveHasError={ autoSaveHasError }
            formDescription={ updatedFormDescription }
            isFormCompleted={ isFormCompletedUpdated }
            isSpainBusiness={ isSpainBusiness }
            isSubmitLoading={ isLoading }
            isVaultSubmitted={ isVaultSubmitted || isVaultDisabled }
          >
            <Box display={ isFormsTabActive ? 'block' : 'none' } w='100%'>
              <FormsTab
                applicantsKYC={ applicantsKYC }
                isSpainBusiness={ isSpainBusiness }
                isVaultSubmitted={ isVaultSubmitted }
                isVaultDisabled={ isVaultDisabled }
              />
            </Box>
            <Box display={ isDocumentsTabActive ? 'block' : 'none' } w='100%'>
              <DocumentsTab
                applicantsDocuments={ applicantsDocuments }
                opportunityId={ opportunityId }
              />
            </Box>
            <Box
              display={ isMortgageTimelineTabActive ? 'block' : 'none' }
              w='100%'
              my='4'
            >
              <MortgageTimeline activeVaultTab={ activeVaultTab } />
            </Box>
          </VaultAppShell>
        </form>
      </FormOperationsProvider>
      <SubmitSlido
        isOpen={ isOpen }
        onSubmit={ handleSubmit }
        onContinue={ () =>
          setActiveVaultTab(
            isSpainBusiness ? VAULT_TAB.FORMS : VAULT_TAB.MORTGAGE_TIMELINE
          ) }
        close={ close }
        isLoading={ isLoading }
        isVaultSubmitted={ isVaultSubmitted }
        numOfMissingFields={ fieldsCount.total - fieldsCount.count }
        isSpainBusiness={ isSpainBusiness }
      />
    </VaultFormProvider>
  );
};

const Home = ({ isAutoSaveEnabled }: { isAutoSaveEnabled: boolean }) => {
  const oppId = useGetOpportunityId();

  const { data, isLoading: isKYCDataLoading } = useKYCData<KYCCountry>(oppId, !isAutoSaveEnabled);
  const { data: documentData, isLoading: isDocumentDataLoading } = useVaultData(oppId);
  const {
    data: vaultProgressData,
    isLoading: isVaultProgressLoading,
    error,
  } = useVaultProgress(oppId);
  const { t } = useTranslation();
  const { getBrokerId } = useBrokerageStore();

  const { logOut } = useAuthStore();
  const navigate = useNavigate();

  const applicantsDocuments = documentData.applicants;
  const isSpainBusiness = vaultProgressData.businessIdentifier === BUSINESS_IDENTIFIER.ES_BAYTECA;
  const { count, total, percentage } = vaultProgressData.kycProgress;
  const formDescription = t('home.mandatoryFields', { count, total });

  const {
    data: vaultStorageData,
    isLoading: isVaultStorageDataLoading,
    error: vaultStorageError,
  } = useGetVaultStorage(oppId, isAutoSaveEnabled);

  useEffect(() => {
    if (vaultProgressData.businessIdentifier) {
      updateAmplitudeUserProperties({
        opportunity_id: oppId,
        broker_id: getBrokerId(),
        brokerage_id: documentData.brokerage?.external_id,
        brokerage_name: documentData.brokerage?.name,
        business_identifier: vaultProgressData.businessIdentifier,
      });
    }
  }, [
    documentData.brokerage?.external_id,
    documentData.brokerage?.name,
    vaultProgressData.businessIdentifier
  ]);

  if (error?.statusCode === 403) {
    logOut();
    resetAmplitudeIdentity();
    navigate('/');
  }

  if (
    isKYCDataLoading
    || isDocumentDataLoading
    || isVaultProgressLoading
    || isVaultStorageDataLoading
  ) {
    return <VaultAppShellFallback status='loading' />;
  }

  if (vaultStorageError) {
    return <VaultAppShellFallback status='error' />;
  }

  const applicantsKYC = vaultStorageData?.vault_data_json.applicants || convertToApplicantType(data.kyc);

  const isVaultSubmitted = isAutoSaveEnabled && vaultStorageData?.vault_status === 'submitted';
  const isVaultDisabled = isAutoSaveEnabled && vaultStorageData?.vault_status === 'disabled';

  return (
    <Form
      opportunityId={ oppId! }
      applicantsKYC={ applicantsKYC }
      applicantsDocuments={ applicantsDocuments }
      isSpainBusiness={ isSpainBusiness }
      formDescription={ formDescription }
      isFormCompleted={ percentage === 100 }
      isVaultSubmitted={ isVaultSubmitted }
      isVaultDisabled={ isVaultDisabled }
    />
  );
};

const HomeWithAutoSave = () => {
  const { setIsAutoSave } = useAuthStore();

  useEffect(() => {
    setIsAutoSave(true);
  }, []);

  return <Home isAutoSaveEnabled />;
};

export default HomeWithAutoSave;
