/* eslint-disable no-restricted-syntax */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';
import { kycApi } from '@modules/kyc/api';
import {
  KYCCountry,
  UpdateKycData,
} from '@huspy/forge/types';
import { VaultFormValues } from '@modules/core/pages/Home/form-context';
import { useState } from 'react';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { useAuthStore, useBrokerageStore } from '@modules/core/store';
import { vaultFormUtils } from '@huspy/forge/features';

const {
  cleanObject,
  transformResidentialInformation,
  transformEmploymentInformation,
  transformIncomeInformation,
  flattenRequestErrors,
} = vaultFormUtils;

/**
 * Custom hook for updating KYC data
 * @param opportunityId Unique identifier for the opportunity
 * @returns Mutation hook for updating KYC data
 */
const useUpdateKYCData = <TCountry extends KYCCountry = 'AE'>(
  opportunityId: string
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [error, setError] = useState({});
  const { data: brokerage } = useBrokerageStore();
  const { getClientId } = useAuthStore();

  const { mutate, mutateAsync, isPending } = useMutation({
    mutationKey: ['updateKyc', opportunityId],
    mutationFn: async (data: VaultFormValues<TCountry>) => {
      setError({});
      trackAmplitudeEvent(USER_EVENTS.FORM_FIELDS.VAULT_FIELDS_SAVE_ATTEMPTED, {
        brokerage_external_id: brokerage?.external_id,
        brokerage_name: brokerage?.name,
        case_id: opportunityId,
        client_id: getClientId(),
      });
      const updatePromises = data.applicants.map(async (applicant) => {
        const body: UpdateKycData<KYCCountry> = {
          personal_information: cleanObject(applicant.personal_information),
          residential_information: transformResidentialInformation(
            applicant.residential_information
          ),
          employment_information: transformEmploymentInformation(
            applicant.employment_information
          ),
          financial_information: transformIncomeInformation({
            incomes: applicant.incomes,
            autoLoan: applicant.autoLoan,
            mortgage: applicant.mortgage,
            creditCard: applicant.creditCard,
            personalLoan: applicant.personalLoan,
          }),
        };

        return kycApi.updateKYCData<TCountry>(
          opportunityId,
          applicant.applicantId,
          body
        );
      });

      return Promise.allSettled(updatePromises);
    },
    onSuccess: (responses) => {
      const requestErrors = flattenRequestErrors(responses);
      setError(requestErrors);

      const hasErrors = responses.some((res) => res.status === 'rejected');

      queryClient.invalidateQueries({ queryKey: ['vaultProgress', opportunityId] });
      queryClient.invalidateQueries({ queryKey: ['kyc', opportunityId] });
      if (hasErrors) {
        trackAmplitudeEvent(USER_EVENTS.FORM_FIELDS.VAULT_FIELDS_SAVE_FAILED, {
          client_id: getClientId(),
          brokerage_external_id: brokerage?.external_id,
          brokerage_name: brokerage?.name,
          case_id: opportunityId,
          error: responses
            .filter((res) => res.status === 'rejected')
            .map((res) => res.reason?.data),
        });
        throw new Error(t('kyc.notifications.kycUpdateFailed'));
      } else {
        trackAmplitudeEvent(USER_EVENTS.FORM_FIELDS.VAULT_FIELDS_SAVE_COMPLETED, {
          brokerage_external_id: brokerage?.external_id,
          brokerage_name: brokerage?.name,
          case_id: opportunityId,
          client_id: getClientId(),
        });
        toast('info', { message: t('kyc.notifications.kycUpdated') });
      }
    },
    onError: () => {
      toast('error', { message: t('kyc.notifications.kycUpdateFailed') });
    },
  });

  return {
    mutate,
    mutateAsync,
    error,
    isPending,
  };
};

export default useUpdateKYCData;
