import { notifications as p } from "@mantine/notifications";
import s from "react";
import { t as c } from "./vanilla-LyKl0mEw.mjs";
import { c as i, T as y } from "./createReactComponent-Btb2fexK.mjs";
var g = i(
  "alert-circle-filled",
  "IconAlertCircleFilled",
  [
    [
      "path",
      {
        d: "M12 2c5.523 0 10 4.477 10 10a10 10 0 0 1 -19.995 .324l-.005 -.324l.004 -.28c.148 -5.393 4.566 -9.72 9.996 -9.72zm.01 13l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-.01 -8a1 1 0 0 0 -.993 .883l-.007 .117v4l.007 .117a1 1 0 0 0 1.986 0l.007 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z",
        fill: "currentColor",
        key: "svg-0",
        strokeWidth: "0"
      }
    ]
  ]
), C = i(
  "circle-check-filled",
  "IconCircleCheckFilled",
  [
    [
      "path",
      {
        d: "M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z",
        fill: "currentColor",
        key: "svg-0",
        strokeWidth: "0"
      }
    ]
  ]
), k = i("circle-x-filled", "IconCircleXFilled", [
  [
    "path",
    {
      d: "M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z",
      fill: "currentColor",
      key: "svg-0",
      strokeWidth: "0"
    }
  ]
]), w = i(
  "info-circle-filled",
  "IconInfoCircleFilled",
  [
    [
      "path",
      {
        d: "M12 2c5.523 0 10 4.477 10 10a10 10 0 0 1 -19.995 .324l-.005 -.324l.004 -.28c.148 -5.393 4.566 -9.72 9.996 -9.72zm0 9h-1l-.117 .007a1 1 0 0 0 0 1.986l.117 .007v3l.007 .117a1 1 0 0 0 .876 .876l.117 .007h1l.117 -.007a1 1 0 0 0 .876 -.876l.007 -.117l-.007 -.117a1 1 0 0 0 -.764 -.857l-.112 -.02l-.117 -.006v-3l-.007 -.117a1 1 0 0 0 -.876 -.876l-.117 -.007zm.01 -3l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z",
        fill: "currentColor",
        key: "svg-0",
        strokeWidth: "0"
      }
    ]
  ]
), v = { root: "_1u5mhob0" };
const I = {
  warning: c.colors.warning[1],
  info: c.colors.positive[1],
  error: c.colors.negative[1],
  neutral: c.colors.neutral[2]
}, F = {
  warning: /* @__PURE__ */ s.createElement(g, null),
  info: /* @__PURE__ */ s.createElement(C, null),
  error: /* @__PURE__ */ s.createElement(k, null),
  neutral: /* @__PURE__ */ s.createElement(
    w,
    {
      color: "black",
      stroke: "black",
      style: { color: "black", stroke: "black", fill: "black" }
    }
  )
}, z = (e, { message: l, duration: t }) => {
  p.show({
    withCloseButton: !1,
    h: "46px",
    autoClose: t ?? 3e3,
    message: /* @__PURE__ */ s.createElement(y, { c: e === "neutral" ? "black" : "neutral.0", size: "md" }, l),
    color: "transparent",
    icon: F[e],
    loading: !1,
    classNames: { root: v.root },
    styles: {
      root: { backgroundColor: I[e], width: "fit-content" },
      description: { color: c.colors.neutral[0] },
      icon: {
        marginLeft: 0,
        marginRight: c.spacing.sm,
        width: "20px",
        height: "20px"
      }
    }
  });
}, T = (e) => {
  const l = new Date(e), t = (/* @__PURE__ */ new Date()).toISOString().slice(0, -1), o = new Date(t), r = Math.abs(o.getTime() - l.getTime()), n = 5 * 60 * 1e3;
  return r > n;
};
async function f(e, l = 3) {
  try {
    const t = await fetch(e);
    if (!t.ok)
      throw new Error(`Failed to fetch stylesheet: ${t.statusText}`);
    return await t.text();
  } catch (t) {
    return l > 0 ? (console.warn(`Retry fetching stylesheet (${l} attempts left):`, e), f(e, l - 1)) : (console.warn("Could not fetch stylesheet:", e, t), "");
  }
}
function d(e) {
  const l = window.getComputedStyle(e);
  let t = "";
  for (let o = 0; o < l.length; o++) {
    const r = l[o], n = l.getPropertyValue(r);
    n && (t += `${r}: ${n}; `);
  }
  t && e.setAttribute("style", t), e.children.length > 0 && [...e.children].forEach((o) => d(o));
}
async function x(e, l) {
  const t = document.querySelector(`#${e}`);
  if (!t)
    return console.error("No element found with id:", e), null;
  const o = t.cloneNode(!0), r = [], n = [];
  document.querySelectorAll("style").forEach((a) => {
    r.push(a.textContent);
  }), document.querySelectorAll('link[rel="stylesheet"]').forEach(async (a) => {
    const { href: u } = a;
    u && n.push(f(u));
  });
  const m = await Promise.all(n);
  r.push(...m), d(o);
  const h = [];
  return document.querySelectorAll('link[rel="stylesheet"][href*="fonts"]').forEach((a) => {
    h.push(a.outerHTML);
  }), `
      <!DOCTYPE html><html><head><meta charset="UTF-8">${h.join("")}
      <title>${l ?? "Document.pdf"}</title>
      <style>${r.join(" ")}</style></head><body>${o.outerHTML}</body></html>
  `.replaceAll(`
`, "").replaceAll(/\s+/g, " ").trim();
}
export {
  x as c,
  T as i,
  z as t
};
