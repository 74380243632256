import React from 'react';
import { Image } from '@mantine/core';
import '@mantine/core/styles/AppShell.css';
import {
  Box, Button, Flex,
} from '@huspy/briks-web';
import { useNavigate } from 'react-router-dom';
import { useAuthStore, useBrokerageStore } from '@modules/core/store';
import { resetAmplitudeIdentity } from '@shared/analytics/amplitude';
import useGetOpportunityId from '@modules/core/hooks/useGetOpportunityId';
import { useQueryClient } from '@tanstack/react-query';
import { visibleOnDesktop } from '../styles.css';
import { AutoSaveLoader } from './AutoSaveLoader';

interface HeaderProps {
  logoutLabel: string;
  isAutoSaveLoading: boolean;
  autoSaveHasError: boolean;
  isVaultSubmitted: boolean;
  hasErrorOrLoading?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  logoutLabel,
  isAutoSaveLoading,
  autoSaveHasError,
  isVaultSubmitted,
  hasErrorOrLoading = false,
}) => {
  const oppId = useGetOpportunityId();
  const { logOut } = useAuthStore();
  const navigate = useNavigate();
  const { data: brokerage } = useBrokerageStore();
  const queryClient = useQueryClient();

  const handleLogout = () => {
    queryClient.invalidateQueries({ queryKey: ['vault-storage', oppId] });
    const navigateLink = `/login/${oppId ?? ''}`;
    resetAmplitudeIdentity();
    logOut();
    navigate(navigateLink);
  };

  return (
    <Flex py='4' px='8' h='100%' justify='space-between' align='center'>
      <Box w='160px' h='50px' overflow='hidden' position='relative'>
        <Image src={ brokerage?.logo ?? '' } maw='100%' mah='100%' fit='cover' />
      </Box>
      <Flex gap='4' justify='center' align='center'>
        {!hasErrorOrLoading && (
          <Box className={ visibleOnDesktop }>
            <AutoSaveLoader
              isLoading={ isAutoSaveLoading }
              hasError={ autoSaveHasError }
              isVaultSubmitted={ isVaultSubmitted }
            />
          </Box>
        )}
        <Button
          variant='secondary'
          size='md'
          type='button'
          onClick={ handleLogout }
          data-testid='logout-btn'
        >
          {logoutLabel}
        </Button>
      </Flex>
    </Flex>
  );
};
