import { kycApi } from '@modules/kyc/api';
import { KYCCountry } from '@huspy/forge/types';
import { useQuery } from '@tanstack/react-query';

const useKYCData = <TCountry extends KYCCountry = 'AE'>(opportunityId?: string | null, enabled = true) => {
  const {
    data, isLoading, error, refetch,
  } = useQuery({
    queryKey: ['kyc', opportunityId],
    queryFn: () => kycApi.getKYCData<TCountry>(opportunityId!),
    enabled,
  });

  return {
    data: { kyc: data ?? {} },
    isLoading,
    error,
    refetch,
    isDisabled: error?.statusCode === 403,
  };
};

export default useKYCData;
