import o, { forwardRef as Q, useState as X, useEffect as Z } from "react";
import { PasswordInput as ee, NumberInput as te, InputBase as re, useProps as ae, useCombobox as ne, CloseButton as se, Combobox as P, Box as oe, ScrollArea as le, Tooltip as ie } from "@mantine/core";
import { t as O } from "./vanilla-LyKl0mEw.mjs";
import { a as j, T as x, c as Y } from "./createReactComponent-Btb2fexK.mjs";
import { t as L } from "i18next";
import E from "clsx";
function ce(t, r) {
  if (typeof t != "object" || t === null)
    return t;
  var n = t[Symbol.toPrimitive];
  if (n !== void 0) {
    var s = n.call(t, r || "default");
    if (typeof s != "object")
      return s;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (r === "string" ? String : Number)(t);
}
function ue(t) {
  var r = ce(t, "string");
  return typeof r == "symbol" ? r : String(r);
}
function de(t, r, n) {
  return r = ue(r), r in t ? Object.defineProperty(t, r, {
    value: n,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : t[r] = n, t;
}
function M(t, r) {
  var n = Object.keys(t);
  if (Object.getOwnPropertySymbols) {
    var s = Object.getOwnPropertySymbols(t);
    r && (s = s.filter(function(d) {
      return Object.getOwnPropertyDescriptor(t, d).enumerable;
    })), n.push.apply(n, s);
  }
  return n;
}
function W(t) {
  for (var r = 1; r < arguments.length; r++) {
    var n = arguments[r] != null ? arguments[r] : {};
    r % 2 ? M(Object(n), !0).forEach(function(s) {
      de(t, s, n[s]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(t, Object.getOwnPropertyDescriptors(n)) : M(Object(n)).forEach(function(s) {
      Object.defineProperty(t, s, Object.getOwnPropertyDescriptor(n, s));
    });
  }
  return t;
}
var fe = (t) => function() {
  for (var r = arguments.length, n = new Array(r), s = 0; s < r; s++)
    n[s] = arguments[s];
  var d = Object.assign({}, ...n.map((c) => c.styles)), v = Object.keys(d), h = v.filter((c) => "mappings" in d[c]), m = (c) => {
    var p = [], w = {}, i = W({}, c), g = !1;
    for (var y of h) {
      var u = c[y];
      if (u != null) {
        var S = d[y];
        g = !0;
        for (var a of S.mappings)
          w[a] = u, i[a] == null && delete i[a];
      }
    }
    var b = g ? W(W({}, w), i) : c, D = function() {
      var e = b[f], l = d[f];
      try {
        if (l.mappings)
          return "continue";
        if (typeof e == "string" || typeof e == "number") {
          if (process.env.NODE_ENV !== "production" && !l.values[e].defaultClass)
            throw new Error();
          p.push(l.values[e].defaultClass);
        } else if (Array.isArray(e))
          for (var k = 0; k < e.length; k++) {
            var V = e[k];
            if (V != null) {
              var B = l.responsiveArray[k];
              if (process.env.NODE_ENV !== "production" && !l.values[V].conditions[B])
                throw new Error();
              p.push(l.values[V].conditions[B]);
            }
          }
        else
          for (var A in e) {
            var I = e[A];
            if (I != null) {
              if (process.env.NODE_ENV !== "production" && !l.values[I].conditions[A])
                throw new Error();
              p.push(l.values[I].conditions[A]);
            }
          }
      } catch (G) {
        if (process.env.NODE_ENV !== "production") {
          class C extends Error {
            constructor(H) {
              super(H), this.name = "SprinklesError";
            }
          }
          var N = (_) => typeof _ == "string" ? '"'.concat(_, '"') : _, F = (_, H, J) => {
            throw new C('"'.concat(_, '" has no value ').concat(N(H), ". Possible values are ").concat(Object.keys(J).map(N).join(", ")));
          };
          if (!l)
            throw new C('"'.concat(f, '" is not a valid sprinkle'));
          if ((typeof e == "string" || typeof e == "number") && (e in l.values || F(f, e, l.values), !l.values[e].defaultClass))
            throw new C('"'.concat(f, '" has no default condition. You must specify which conditions to target explicitly. Possible options are ').concat(Object.keys(l.values[e].conditions).map(N).join(", ")));
          if (typeof e == "object") {
            if (!("conditions" in l.values[Object.keys(l.values)[0]]))
              throw new C('"'.concat(f, '" is not a conditional property'));
            if (Array.isArray(e)) {
              if (!("responsiveArray" in l))
                throw new C('"'.concat(f, '" does not support responsive arrays'));
              var K = l.responsiveArray.length;
              if (K < e.length)
                throw new C('"'.concat(f, '" only supports up to ').concat(K, " breakpoints. You passed ").concat(e.length));
              for (var $ of e)
                l.values[$] || F(f, $, l.values);
            } else
              for (var R in e) {
                var z = e[R];
                if (z != null && (l.values[z] || F(f, z, l.values), !l.values[z].conditions[R]))
                  throw new C('"'.concat(f, '" has no condition named ').concat(N(R), ". Possible values are ").concat(Object.keys(l.values[z].conditions).map(N).join(", ")));
              }
          }
        }
        throw G;
      }
    };
    for (var f in b)
      var T = D();
    return t(p.join(" "));
  };
  return Object.assign(m, {
    properties: new Set(v)
  });
}, pe = (t) => t, me = function() {
  return fe(pe)(...arguments);
}, U = me({ conditions: void 0, styles: { fontWeight: { values: { "fw-100": { defaultClass: "_1svxe9w0" }, "fw-200": { defaultClass: "_1svxe9w1" }, "fw-300": { defaultClass: "_1svxe9w2" }, "fw-400": { defaultClass: "_1svxe9w3" }, "fw-500": { defaultClass: "_1svxe9w4" }, "fw-600": { defaultClass: "_1svxe9w5" }, "fw-700": { defaultClass: "_1svxe9w6" }, "fw-800": { defaultClass: "_1svxe9w7" } } } } }), ve = j({ defaultClassName: "_3a0d740", variantClassNames: { disabled: { true: "_3a0d741", false: "_3a0d742" } }, defaultVariants: {}, compoundVariants: [] }), he = "ttmgoh0", we = j({ defaultClassName: "_18id84m0", variantClassNames: { size: { lg: "_18id84m1", md: "_18id84m2" }, hasTrail: { true: "_18id84m3", false: "_18id84m4" }, hasHead: { true: "_18id84m5", false: "_18id84m6" } }, defaultVariants: {}, compoundVariants: [[{ hasTrail: !0, size: "md" }, "_18id84m7"], [{ hasTrail: !0, size: "lg" }, "_18id84m8"], [{ hasHead: !0, size: "md" }, "_18id84m9"], [{ hasHead: !0, size: "lg" }, "_18id84ma"]] }), be = j({ defaultClassName: "_18id84mb", variantClassNames: { size: { lg: "_18id84mc", md: "_18id84md" } }, defaultVariants: {}, compoundVariants: [] }), ge = j({ defaultClassName: "_1j0ihqb0", variantClassNames: { size: { lg: "_1j0ihqb1", md: "_1j0ihqb2" }, hasTrail: { true: "_1j0ihqb3", false: "_1j0ihqb4" }, hasHead: { true: "_1j0ihqb5", false: "_1j0ihqb6" } }, defaultVariants: {}, compoundVariants: [[{ size: "lg", hasTrail: !0 }, "_1j0ihqb7"], [{ size: "md", hasTrail: !0 }, "_1j0ihqb8"]] }), ye = "_1ihlpde0", Se = j({ defaultClassName: "qbd1q70", variantClassNames: { size: { lg: "qbd1q71", md: "qbd1q72" } }, defaultVariants: {}, compoundVariants: [] });
const Ce = ({
  styles: t,
  classNames: r,
  disabled: n,
  size: s = "md",
  success: d,
  errorMessage: v,
  trail: h,
  type: m,
  head: c
}) => {
  const p = !!((d ?? v ?? h) || m === "password"), {
    // @ts-expect-error
    wrapper: w,
    input: i,
    error: g,
    section: y,
    root: u,
    label: S,
    description: a
  } = { ...r };
  return {
    inputClassNames: {
      ...r,
      label: E(U({ fontWeight: "fw-400" }), ve({ disabled: n }), S),
      wrapper: E(be({ size: s }), w),
      input: E(we({ size: s, hasTrail: p, hasHead: !!c }), i),
      error: E(he, U({ fontWeight: "fw-600" }), g),
      section: E(ge({ size: s, hasTrail: p }), y),
      root: E(ye, u),
      description: E(Se({ size: s }), a)
    },
    inputStyles: t
  };
}, Ee = Q(function(r, n) {
  const { inputClassNames: s, inputStyles: d } = Ce(r), {
    trail: v,
    disabled: h,
    errorMessage: m,
    placeholder: c,
    head: p,
    noNumberSeparators: w,
    type: i,
    classNames: g,
    styles: y,
    ...u
  } = r, S = typeof v == "string" ? /* @__PURE__ */ o.createElement(x, { size: "sm", c: h ? "neutral.5" : "neutral.6" }, v) : v;
  return i === "password" ? (
    // @ts-ignore
    /* @__PURE__ */ o.createElement(
      ee,
      {
        leftSection: p,
        disabled: h,
        classNames: s,
        styles: { ...d, visibilityToggle: { color: "black" } },
        placeholder: c,
        error: m,
        type: i,
        ref: n,
        ...u
      }
    )
  ) : i === "number" ? /* @__PURE__ */ o.createElement(
    te,
    {
      leftSection: p,
      rightSection: S,
      labelProps: { size: u.size },
      rightSectionPointerEvents: "none",
      disabled: h,
      placeholder: c,
      classNames: s,
      ...!w && {
        thousandSeparator: L("common.thousandSeparator") ?? ",",
        decimalSeparator: L("common.decimalSeparator") ?? "."
      },
      styles: d,
      error: m,
      ref: n,
      inputWrapperOrder: ["label", "input", "description", "error"],
      ...u,
      onChange: (a) => {
        var b;
        typeof a == "string" && a.length === 0 && (a = void 0), (b = u.onChange) == null || b.call(u, a);
      }
    }
  ) : /* @__PURE__ */ o.createElement(
    re,
    {
      leftSection: p,
      rightSection: v,
      labelProps: { size: u.size },
      rightSectionPointerEvents: "none",
      disabled: h,
      placeholder: c,
      classNames: s,
      styles: d,
      error: m,
      type: i,
      ref: n,
      inputWrapperOrder: ["label", "input", "description", "error"],
      ...u,
      onChange: (a) => {
        var b;
        typeof a.target.value == "string" && a.target.value.length === 0 && (a.target.value = null), (b = u.onChange) == null || b.call(u, a);
      }
    }
  );
});
var _e = Y("chevron-down", "IconChevronDown", [
  ["path", { d: "M6 9l6 6l6 -6", key: "svg-0" }]
]), Oe = Y("chevron-up", "IconChevronUp", [
  ["path", { d: "M6 15l6 -6l6 6", key: "svg-0" }]
]), je = j({ defaultClassName: "ovskww2 ovskww1", variantClassNames: { size: { lg: "ovskww3", md: "ovskww4" } }, defaultVariants: {}, compoundVariants: [] }), Ne = "ovskww0", ze = "ovskww5";
function Pe({ label: t, description: r }) {
  return /* @__PURE__ */ o.createElement(o.Fragment, null, /* @__PURE__ */ o.createElement(x, { fz: "sm", fw: 500, m: 0 }, t), r && /* @__PURE__ */ o.createElement(x, { fz: "xs", c: "neutral.6", fw: 400, m: 0, mt: O.spacing.sm }, r));
}
const Ae = (t) => {
  const {
    size: r = "md",
    data: n,
    disabled: s,
    placeholder: d,
    dropdownScroll: v = !1,
    dropdownMaxHeight: h = 340,
    allowSearch: m,
    value: c,
    isClearable: p,
    ...w
  } = ae("Select", {}, t), i = ne({ onDropdownClose: () => i.resetSelectedOption() }), [g, y] = X(""), S = n.every((e) => e.label !== g) ? n.filter((e) => e.label.toLowerCase().includes(g.toLowerCase().trim())) : n, a = n.find((e) => e.value === c);
  Z(() => {
    c === null || !m || y((a == null ? void 0 : a.label) ?? "");
  }, [c]);
  const b = () => {
    i.closeDropdown(), m && y((a == null ? void 0 : a.label) || "");
  }, D = m && i.dropdownOpened ? g : a == null ? void 0 : a.label, f = r === "md" ? { height: 16, width: 16 } : { height: 20, width: 20 }, T = /* @__PURE__ */ o.createElement(o.Fragment, null, p && (a != null && a.value) ? /* @__PURE__ */ o.createElement(
    se,
    {
      size: 20,
      onMouseDown: (e) => e.preventDefault(),
      styles: { root: { color: O.colors.neutral[8] } },
      onClick: () => t.onChange(""),
      "aria-label": "Clear value"
    }
  ) : /* @__PURE__ */ o.createElement(o.Fragment, null, i.dropdownOpened ? /* @__PURE__ */ o.createElement(Oe, { color: O.colors.neutral[8], ...f }) : /* @__PURE__ */ o.createElement(_e, { ...f, color: O.colors.neutral[8] }))), q = S.length > 0 ? S.map((e, l) => /* @__PURE__ */ o.createElement(
    P.Option,
    {
      disabled: e.disabled,
      value: e.value,
      key: e.value,
      "data-test": `${w["data-test"]}-option-${l}`
    },
    /* @__PURE__ */ o.createElement(Pe, { ...e })
  )) : /* @__PURE__ */ o.createElement(oe, { className: ze }, /* @__PURE__ */ o.createElement(x, { fz: "sm", fw: 500, c: "neutral.6" }, "No results"));
  return /* @__PURE__ */ o.createElement(
    P,
    {
      offset: 4,
      withinPortal: !1,
      store: i,
      __staticSelector: "Select",
      classNames: {
        dropdown: Ne,
        option: je({ size: "md" })
      },
      disabled: s,
      onOptionSubmit: (e) => {
        w.onChange(e), i.closeDropdown();
      }
    },
    /* @__PURE__ */ o.createElement(P.Target, null, /* @__PURE__ */ o.createElement(
      Ee,
      {
        ...w,
        trail: T,
        onClick: () => i.openDropdown(),
        onFocus: () => i.openDropdown(),
        onBlur: b,
        disabled: s,
        placeholder: d,
        pointer: !0,
        size: r,
        value: D ?? "",
        ...m ? {
          contentEditable: !0,
          onChange: (e) => {
            i.updateSelectedOptionIndex(), y(e.currentTarget.value);
          }
        } : { onKeyDown: (e) => {
          e.preventDefault();
        }, contentEditable: !1 }
      }
    )),
    /* @__PURE__ */ o.createElement(P.Dropdown, { mah: h }, /* @__PURE__ */ o.createElement(P.Options, null, v ? /* @__PURE__ */ o.createElement(le.Autosize, { mah: h, type: "always", scrollbarSize: 10, scrollbars: "y" }, q) : q))
  );
}, Ie = (t) => /* @__PURE__ */ o.createElement(
  ie,
  {
    withArrow: !0,
    multiline: !0,
    arrowSize: 12,
    color: "neutral.7",
    p: O.spacing.sm,
    transitionProps: { transition: "pop", duration: 300 },
    styles: {
      tooltip: {
        borderRadius: "8px",
        fontSize: O.fontSizes.xs
        // textAlign: 'center',
      }
    },
    ...t
  }
);
export {
  Ee as I,
  Ae as S,
  Ie as T,
  je as a,
  Ne as d
};
