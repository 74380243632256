/* eslint-disable no-restricted-syntax */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';
import { kycApi } from '@modules/kyc/api';
import { KYCCountry, UpdateKycData } from '@huspy/forge/types';
import { VaultFormValues } from '@modules/core/pages/Home/form-context';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { useAuthStore, useBrokerageStore } from '@modules/core/store';
import { vaultFormUtils } from '@huspy/forge/features';

const getEmptyOptionalFields = (data: VaultFormValues<KYCCountry>) => {
  const emptyOptionalFields: string[] = [];
  data.applicants.forEach((applicant) => {
    const { applicantType } = applicant;
    if (applicant.autoLoan.length === 0) {
      emptyOptionalFields.push(`${applicantType}.autoLoan`);
    }
    if (applicant.mortgage.length === 0) {
      emptyOptionalFields.push(`${applicantType}.mortgage`);
    }
    if (applicant.creditCard.length === 0) {
      emptyOptionalFields.push(`${applicantType}.creditCard`);
    }
    if (applicant.personalLoan.length === 0) {
      emptyOptionalFields.push(`${applicantType}.personalLoan`);
    }
  });
  return emptyOptionalFields;
};

const {
  cleanObject,
  transformResidentialInformation,
  transformEmploymentInformation,
  transformIncomeInformation,
  flattenValidationErrors,
} = vaultFormUtils;

/**
 * Custom hook for updating KYC data
 * @param opportunityId Unique identifier for the opportunity
 * @returns Mutation hook for updating KYC data
 */
export const useSubmitVault = <TCountry extends KYCCountry = 'AE'>(
  opportunityId: string
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: brokerage } = useBrokerageStore();
  const { getClientId } = useAuthStore();

  const {
    mutate, mutateAsync, isPending, error,
  } = useMutation({
    mutationKey: ['submit-vault', opportunityId],
    mutationFn: async (body: {
      data: VaultFormValues<TCountry>;
      missingFields: string[];
    }) => {
      const { data } = body;
      trackAmplitudeEvent(
        USER_EVENTS.FORM_FIELDS.VAULT_FIELDS_SUBMISSION_ATTEMPTED,
        {
          brokerage_external_id: brokerage?.external_id,
          brokerage_name: brokerage?.name,
          case_id: opportunityId,
          client_id: getClientId(),
        }
      );
      const applicantsData: UpdateKycData<KYCCountry>[] = data.applicants.map(
        (applicant) => ({
          applicant_id: applicant.applicantId,
          applicant_type: applicant.applicantType,
          personal_information: cleanObject(applicant.personal_information),
          residential_information: transformResidentialInformation(
            applicant.residential_information
          ),
          employment_information: transformEmploymentInformation(
            applicant.employment_information
          ),
          financial_information: transformIncomeInformation({
            incomes: applicant.incomes,
            autoLoan: applicant.autoLoan,
            mortgage: applicant.mortgage,
            creditCard: applicant.creditCard,
            personalLoan: applicant.personalLoan,
          }),
        })
      );
      return kycApi.submitVault(opportunityId, {
        applicants: applicantsData,
        actor_type: 'client',
        set_vault_storage_submitted: true,
      });
    },
    onSuccess: (_, { data, missingFields }) => {
      queryClient.invalidateQueries({ queryKey: ['vaultProgress', opportunityId] });
      queryClient.invalidateQueries({ queryKey: ['kyc', opportunityId] });
      queryClient.invalidateQueries({ queryKey: ['vault-storage', opportunityId] });
      trackAmplitudeEvent(
        USER_EVENTS.FORM_FIELDS.VAULT_FIELDS_SUBMISSION_COMPLETED,
        {
          brokerage_external_id: brokerage?.external_id,
          brokerage_name: brokerage?.name,
          case_id: opportunityId,
          client_id: getClientId(),
          empty_mandatory_fields: missingFields,
          empty_optional_fields: getEmptyOptionalFields(data),
        }
      );
    },
    onError: () => {
      trackAmplitudeEvent(
        USER_EVENTS.FORM_FIELDS.VAULT_FIELDS_SUBMISSION_FAILED,
        {
          client_id: getClientId(),
          brokerage_external_id: brokerage?.external_id,
          brokerage_name: brokerage?.name,
          case_id: opportunityId,
          error: 'error',
        }
      );
      toast('error', { message: t('kyc.notifications.kycUpdateFailed') });
    },
  });

  return {
    mutate,
    mutateAsync,
    error: flattenValidationErrors(error?.data || {}),
    isPending,
  };
};
