import React from 'react';
import ReactDOM from 'react-dom/client';
import { ForgeProvider } from '@huspy/forge';
import '@app/i18n';
import { RouterProvider } from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import '@huspy/forge/style.css';
import '@mantine/core/styles/Stepper.css';
import '@mantine/core/styles/Avatar.css';
import '@mantine/core/styles/Grid.css';
import '@mantine/dates/styles.layer.css';
import '../styled-system/styles.css';
import '@huspy/styled-system/styles.css';
import '@mantine/core/styles/Timeline.css';
import '@mantine/core/styles/Drawer.layer.css';
import './App.css';
// Briks styles
import '@huspy/forge/styles.css';
import { Notifications } from '@mantine/notifications';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { init as initAmplitude } from '@amplitude/analytics-browser';
import { router } from '@app/router';
import { initDataDog } from '@app/logger';
import { toast } from '@huspy/forge/shared';
import BehindMaintenance from '@components/BehindMaintenance';

if (ENV !== 'local') {
  initAmplitude(AMPLITUDE_API_KEY);
  initDataDog();
}

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

ReactDOM.createRoot(document.querySelector('#root')!).render(
  <React.StrictMode>
    <ForgeProvider classNamesPrefix='vlt'>
      <QueryClientProvider client={ queryClient }>

        <BehindMaintenance>
          <Notifications
            position='bottom-right'
          />
          <RouterProvider router={ router } />
          <ReactQueryDevtools initialIsOpen={ false } />
        </BehindMaintenance>

      </QueryClientProvider>
    </ForgeProvider>
  </React.StrictMode>
);

window.addEventListener('offline', () =>
  toast('error', { message: 'No internet connection', duration: 5000 }));
