import { Button, Flex } from '@huspy/briks-web';
import { useTranslation } from 'react-i18next';
import { SubmitSlidoType } from '../types';

export const Footer = ({
  close,
  onSubmit,
  onContinue,
  isLoading,
  isVaultSubmitted,
}: Omit<SubmitSlidoType, 'isOpen' | 'numOfMissingFields' | 'isSpainBusiness'>) => {
  const { t } = useTranslation();
  if (isVaultSubmitted) {
    return (
      <Flex gap='2' justify='end'>
        <Button
          variant='primary'
          loading={ isLoading }
          onClick={ () => {
            onContinue();
            close();
          } }
          data-testid='continue-btn'
        >
          {t('home.slido.success.continueBtn')}
        </Button>
      </Flex>
    );
  }
  return (
    <Flex gap='2' justify='end'>
      <Button variant='secondary' onClick={ close } data-testid='close-slido-btn'>
        {t('home.slido.submit.closeBtn')}
      </Button>
      <Button
        variant={ isLoading ? 'secondary' : 'primary' }
        loading={ isLoading }
        onClick={ onSubmit }
        data-testid='form-submit-btn'
      >
        {t('home.slido.submit.saveBtn')}
      </Button>
    </Flex>
  );
};
