import { Flex, Text } from '@huspy/briks-web';
import { useTranslation } from 'react-i18next';
import { Image } from '@mantine/core';
import { imagesPath } from '@shared/css.const';

const QuestionIcon = () => (
  <Flex
    h='167px'
    direction='column'
    justify='center'
    align='center'
    bg='neutral.100'
    borderRadius='4'
    borderWidth='1px'
    borderStyle='solid'
    borderColor='neutral.200'
    position='relative'
  >
    <Image width='88px' height='88px' src={ `${imagesPath}/question.png` } />
  </Flex>
);

const baseTranslation = 'home.slido.submit';
export const Confirmation = ({ numOfMissingFields }: {
  numOfMissingFields: number;
}) => {
  const { t } = useTranslation();
  const hasMissingFields = numOfMissingFields > 0;
  return (
    <Flex direction='column' gap='4'>
      <QuestionIcon />
      <Text size='md' color='text-secondary' fontWeight='medium'>
        {t(
          `${baseTranslation}.${hasMissingFields ? 'missingDescription' : 'unEditableDescription'}`
        )}
      </Text>
      <Flex
        bg='bg-error-primary'
        borderRadius='2'
        gap='1'
        p='4'
        direction='column'
        display={ hasMissingFields ? 'block' : 'none' }
      >
        <Text size='xl'>{t('home.navbar.formBtn')}</Text>
        <Text color='text-error-primary_alt' size='md'>
          {t(`${baseTranslation}.numMissingFields`, { num: numOfMissingFields })}
        </Text>
      </Flex>
    </Flex>
  );
};
