import { Brokerage } from '@modules/documents/api/types';
import {
  OPP_ID_KEY,
  VAULT_TOKEN_KEY,
  LNG_PREFERENCE_KEY,
  BROKER_ID,
  CLIENT_ID,
  VAULT_STATUS,
  IS_AUTO_SAVE_ON
} from '@shared/const';
import { create } from 'zustand';

type AuthStore = {
  getClientId: () => string | null;
  setClientId: (id: string) => void;
  getVaultStatus: () => string | null;
  setVaultStatus: (status: string) => void;
  token: string | null;
  setToken: (token?: string) => void;
  saveTokenToSessionStorage: (token: string) => void;
  getTokenFromSessionStorage: () => string | null;
  logOut: () => void;
  getIsAutoSave: () => boolean;
  setIsAutoSave: (isOn: boolean) => void;
};

type LanguageStore = {
  lng: string | null;
  setLanguage: (lng: string) => void;
  getLanguage: () => string | null;
};

type BrokerageStore = {
  data: Brokerage | null;
  getBrokerId: () => string | null;
  setBrokerId: (id: string) => void;
  setBrokerage: (data: Brokerage | null) => void;
};

export const useAuthStore = create<AuthStore>((set) => ({
  token: null,
  setToken: (token) => set(() => ({ token })),
  saveTokenToSessionStorage: (token) => {
    sessionStorage.setItem(VAULT_TOKEN_KEY, token);
  },
  getTokenFromSessionStorage: () => sessionStorage.getItem(VAULT_TOKEN_KEY),
  getClientId: () => sessionStorage.getItem(CLIENT_ID),
  setClientId: (clientId) => {
    sessionStorage.setItem(CLIENT_ID, clientId);
  },
  getVaultStatus: () => sessionStorage.getItem(VAULT_STATUS),
  setVaultStatus: (status) => {
    sessionStorage.setItem(VAULT_STATUS, status);
  },
  logOut: () => set(() => {
    sessionStorage.removeItem(OPP_ID_KEY);
    sessionStorage.removeItem(VAULT_TOKEN_KEY);
    sessionStorage.removeItem(BROKER_ID);
    sessionStorage.removeItem(CLIENT_ID);
    sessionStorage.removeItem(VAULT_STATUS);
    sessionStorage.removeItem(IS_AUTO_SAVE_ON);
    return { token: undefined };
  }),
  getIsAutoSave: () => sessionStorage.getItem(IS_AUTO_SAVE_ON) === 'true',
  setIsAutoSave: (isOn: boolean) => sessionStorage.setItem(IS_AUTO_SAVE_ON, isOn ? 'true' : 'false'),
}));

export const useLanguageStore = create<LanguageStore>((set) => ({
  lng: null,
  setLanguage: (lng) => {
    set(() => ({ lng }));
    sessionStorage.setItem(LNG_PREFERENCE_KEY, lng);
  },
  getLanguage: () => sessionStorage.getItem(LNG_PREFERENCE_KEY),
}));

export const useBrokerageStore = create<BrokerageStore>((set) => ({
  data: null,
  getBrokerId: () => sessionStorage.getItem(BROKER_ID),
  setBrokerId: (brokerId) => {
    sessionStorage.setItem(BROKER_ID, brokerId);
  },
  setBrokerage: (data) => {
    set(() => ({ data }));
  },
}));
