import { Flex, Text } from '@huspy/briks-web';
import briksTheme from '@shared/briksTheme';

const getBgColor = (isCompleted: boolean, isCurrent: boolean) => {
  if (isCompleted) {
    return briksTheme.colors['blue.50'];
  }
  if (isCurrent) {
    return briksTheme.colors['blue.500'];
  }
  return 'white';
};

export const convertToMonthDay = (date: string | Date) => {
  const isoDate = date instanceof Date ? date : new Date(date);
  const day = isoDate.getDate();
  const month = isoDate.toLocaleString('en-US', { month: 'short' });

  return `${month} ${day}`;
};

export const TimelineCard = ({
  id,
  title,
  description,
  date,
  isCompleted,
  isCurrent,
}: {
  id:string;
  title: string;
  description: string;
  date: string;
  isCompleted: boolean;
  isCurrent: boolean;
}) => {
  const bgColor = getBgColor(isCompleted, isCurrent);
  const titleTextColor = isCurrent ? 'white' : 'text-secondary';
  const descriptionTextColor = isCurrent ? 'white' : 'text-tertiary';
  const showDate = isCompleted || isCurrent;
  return (
    <Flex
      id={ id }
      gap='2'
      borderRadius='4'
      direction='column'
      borderWidth='1px'
      borderStyle='solid'
      borderColor='neutral.200'
      p='4'
      w='307px'
      position='relative'
      top='-50px'
      style={ { background: bgColor } }
    >
      <Text size='xl' color={ titleTextColor } fontWeight='medium'>
        {title}
      </Text>
      <Text size='md' color={ descriptionTextColor }>
        {description}
      </Text>
      {showDate && (
        <Text size='sm' color={ descriptionTextColor }>
          {convertToMonthDay(date)}
        </Text>
      )}
    </Flex>
  );
};
