import {
  identify as amplitudeIdentify,
  Identify,
  reset as amplitudeReset,
  setUserId,
  track as amplitudeTrack,
} from '@amplitude/analytics-browser';

export const identifyObj = new Identify();

const identifyAmplitudeSession = ({
  oppId,
  brokerId,
}: {
  oppId: string;
  brokerId: string;
}) => {
  setUserId(oppId);

  if (!identifyObj.getUserProperties || Object.keys(identifyObj.getUserProperties()).length === 0) {
    identifyObj.set('opportunity_id', oppId);
    identifyObj.set('broker_id', brokerId);
  }
  amplitudeIdentify(identifyObj);
};

const updateAmplitudeUserProperties = (newProps: Record<string, any>) => {
  Object.entries(newProps).forEach(([key, value]) => {
    if (key === 'opportunity_id') {
      setUserId(value);
    }
    identifyObj.set(key, value);
  });
  amplitudeIdentify(identifyObj);
};

const trackAmplitudeEvent = (eventName: string, eventParams?: Record<string, unknown>) => {
  amplitudeTrack(eventName, eventParams);
};

const resetAmplitudeIdentity = () => amplitudeReset();

export {
  identifyAmplitudeSession, updateAmplitudeUserProperties, trackAmplitudeEvent, resetAmplitudeIdentity
};
