export const VAULT_TOKEN_KEY = 'vault_token';
export const OPP_ID_KEY = 'opportunity_id';
export const API_V3_URL = `${BACKEND_URL}/api/v3` as const;
export const API_V2_URL = `${BACKEND_URL}/api/v2` as const;
export const API_V1_URL = `${BACKEND_URL}/api/v1` as const;
export const LNG_PREFERENCE_KEY = 'lang_preference';
export const BROKER_ID = 'broker_id';
export const CLIENT_ID = 'client_id';
export const VAULT_STATUS = 'vault_status';
export const IS_AUTO_SAVE_ON = 'is_auto_save_on';
