import '@mantine/core/styles/AppShell.css';
import {
  Box, Button, Flex, Text
} from '@huspy/briks-web';

import { useTranslation } from 'react-i18next';
import { useAuthStore } from '@modules/core/store';
import {
  visibleOnMobile,
  documentTextStyles,
  submitButtonStyles,
} from '../styles.css';
import { VAULT_TAB, VaultTab } from '../../../types';
import { AutoSaveLoader } from './AutoSaveLoader';

interface FooterProps {
  activeVaultTab: VaultTab;
  isAutoSaveLoading: boolean;
  autoSaveHasError: boolean;
  isVaultSubmitted: boolean;
  isSubmitLoading?: boolean;
}
export const Footer = ({
  activeVaultTab,
  isAutoSaveLoading,
  autoSaveHasError,
  isVaultSubmitted,
  isSubmitLoading = false,
}: FooterProps) => {
  const { t } = useTranslation();
  const { getIsAutoSave } = useAuthStore();
  const isAutoSaveOn = getIsAutoSave();

  if (activeVaultTab === VAULT_TAB.FORMS && !isVaultSubmitted) {
    return (
      <Flex
        direction='column'
        gap='2'
        justify='center'
        align='center'
        p='4'
        w='100%'
      >
        <Flex align='end' justify='end' w='100%'>
          <Button
            size='md'
            type='submit'
            data-testid='submit-form-btn'
            variant={ isSubmitLoading ? 'secondary' : 'primary' }
            loading={ isSubmitLoading }
            className={ submitButtonStyles }
          >
            {t(`home.header.${isAutoSaveOn ? 'submitBtn' : 'saveBtn'}`)}
          </Button>
        </Flex>
        <Box className={ visibleOnMobile }>
          <AutoSaveLoader
            isLoading={ isAutoSaveLoading }
            hasError={ autoSaveHasError }
            isVaultSubmitted={ isVaultSubmitted }
          />
        </Box>
      </Flex>
    );
  }

  if (activeVaultTab === VAULT_TAB.DOCUMENTS) {
    return (
      <Flex p='6.5' align='center' justify='center'>
        <Text className={ documentTextStyles } size='lg' color='text-secondary'>
          {t('home.footer.documents')}
        </Text>
      </Flex>
    );
  }

  return null;
};
