import React from 'react';
import '@mantine/core/styles/AppShell.css';
import {
  Box, Button, Flex, Text
} from '@huspy/briks-web';

import {
  Files, FileText, Tasks, Check, Folder
} from '@huspy/briks-icons';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '@modules/core/store';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { Accordion } from '@mantine/core';
import briksTheme from '@shared/briksTheme';
import { VAULT_TAB, VaultTab } from '../../../types';
import { visibleOnMobile } from '../styles.css';

interface BaseTabProps {
  isActive: boolean;
  onClick: () => void;
  dataTest: string;
}

interface TabButtonProps extends BaseTabProps {
  icon: React.ReactNode;
  label: string;
  isFormCompleted?: boolean;
  description?: string;
  isVaultSubmitted?: boolean;
}

interface MobileTabProps extends BaseTabProps {
  label: string;
}

interface TabAccordionProps extends BaseTabProps {
  icon: React.ReactNode;
  label: string;
  children: React.ReactNode;
}

interface DesktopTabsProps {
  activeTab: VaultTab;
  onTabChange: (tab: VaultTab) => void;
  formDescription: string;
  isFormCompleted: boolean;
  isVaultSubmitted: boolean;
  isSpainBusiness: boolean;
}

interface MobileTabsProps {
  activeTab: VaultTab;
  onTabChange: (tab: VaultTab) => void;
  isSpainBusiness: boolean;
}

const indigoColor = briksTheme.colors['indigo.800'];

const getBorderStyles = (
  isActive: boolean,
  isVaultSubmitted: boolean = false
) => {
  if (isVaultSubmitted) return 'unset';
  return `1px solid ${isActive ? indigoColor : briksTheme.colors['neutral.300']}`;
};
const getTextColor = (isActive: boolean) => (isActive ? indigoColor : 'unset');
const getColor = (isActive: boolean) => (isActive ? indigoColor : 'unset');
const getFontWeight = (isActive: boolean) => (isActive ? 'semibold' : 'normal');
const getBgColor = (isActive: boolean, isVaultSubmitted: boolean = false) => {
  if (isActive && isVaultSubmitted) {
    return 'white';
  }
  return isActive ? briksTheme.colors['indigo.50'] : 'unset';
};
const baseTranslation = 'home.navbar';
const formLabel = `${baseTranslation}.formBtn`;
const documentLabel = `${baseTranslation}.documentBtn`;

const NavbarTabButton: React.FC<TabButtonProps> = ({
  isActive,
  onClick,
  icon,
  label,
  dataTest,
  description,
  isFormCompleted,
  isVaultSubmitted = false,
}) => (
  <Button
    variant='ghost'
    size='2xl'
    onClick={ onClick }
    data-testid={ dataTest }
    type='button'
    w={ isVaultSubmitted ? '90%' : '100%' }
    style={ {
      border: getBorderStyles(isActive, isVaultSubmitted),
      background: getBgColor(isActive, isVaultSubmitted),
    } }
  >
    <Flex w='100%' align='center' justify='start' gap='2'>
      {icon}
      <Flex direction='column' align='start' w='100%'>
        <Flex gap='2' justify='space-between' w='100%'>
          <Text
            fontWeight={ getFontWeight(isActive) }
            letterSpacing='xl'
            style={ { color: getTextColor(isActive) } }
          >
            {label}
          </Text>
          {isFormCompleted && (
            <Flex
              justify='center'
              align='center'
              bg='utility-indigo-800'
              borderRadius='full'
              p='1.5'
            >
              <Check color='#fff' width={ 16 } height={ 16 } />
            </Flex>
          )}
        </Flex>
        {description && !isFormCompleted && (
          <Text
            fontWeight='normal'
            size='sm'
            letterSpacing='xl'
            style={ { color: getTextColor(isActive) } }
          >
            {description}
          </Text>
        )}
      </Flex>
    </Flex>
  </Button>
);

const MobileTab: React.FC<MobileTabProps> = ({
  isActive,
  onClick,
  label,
  dataTest,
}) => (
  <Box
    p='4'
    onClick={ onClick }
    bg={ isActive ? 'white' : 'unset' }
    w='50%'
    borderRadius='2'
    textAlign='center'
    data-testid={ dataTest }
  >
    <Text fontWeight='normal'>{label}</Text>
  </Box>
);

const NavbarTabAccordion: React.FC<TabAccordionProps> = ({
  isActive,
  onClick,
  icon,
  label,
  dataTest,
  children,
}) => (
  <Box
    borderRadius='3'
    style={ {
      border: getBorderStyles(isActive),
      background: getBgColor(isActive),
    } }
  >
    <Accordion
      unstyled
      value={ isActive ? 'active' : 'not-active' }
      chevron={ false }
      style={ { cursor: 'pointer' } }
      w='100%'
    >
      <Accordion.Item key='active' value='active' w='100%'>
        <Accordion.Control w='100%'>
          <Box
            data-testid={ dataTest }
            cursor='pointer'
            w='100%'
            py='5'
            px='7'
            onClick={ onClick }
          >
            <Flex w='100%' align='center' justify='start' gap='2'>
              {icon}
              <Flex direction='column' align='start' w='100%'>
                <Flex gap='2' justify='space-between' w='100%'>
                  <Text
                    fontWeight={ getFontWeight(isActive) }
                    letterSpacing='xl'
                    style={ { color: getTextColor(isActive) } }
                  >
                    {label}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Accordion.Control>
        <Accordion.Panel>
          <Flex
            direction='column'
            gap='2'
            justify='center'
            align='center'
            my='2'
          >
            {children}
          </Flex>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  </Box>
);

export const MobileNavbarTabs: React.FC<MobileTabsProps> = ({
  activeTab,
  onTabChange,
  isSpainBusiness,
}) => {
  const { t } = useTranslation();
  return (
    <Box bg='blue.50' p='4' className={ visibleOnMobile }>
      <Flex
        bg='neutral.100'
        borderWidth='2'
        width='100%'
        borderRadius='2'
        justify='space-between'
      >
        <MobileTab
          isActive={ activeTab === VAULT_TAB.FORMS }
          onClick={ () => onTabChange(VAULT_TAB.FORMS) }
          label={ t(formLabel) }
          dataTest='formBtn-mobile'
        />
        <MobileTab
          isActive={ activeTab === VAULT_TAB.DOCUMENTS }
          onClick={ () => onTabChange(VAULT_TAB.DOCUMENTS) }
          label={ t(documentLabel) }
          dataTest='documentBtn-mobile'
        />
        {!isSpainBusiness && (
          <MobileTab
            isActive={ activeTab === VAULT_TAB.MORTGAGE_TIMELINE }
            onClick={ () => onTabChange(VAULT_TAB.MORTGAGE_TIMELINE) }
            label={ t(`${baseTranslation}.mortgageTimelineMobileBtn`) }
            dataTest='mortgageTimelineBtn-mobile'
          />
        )}
      </Flex>
    </Box>
  );
};

export const DesktopNavbarTabs = ({
  activeTab,
  onTabChange,
  formDescription,
  isFormCompleted,
  isVaultSubmitted,
  isSpainBusiness,
}: DesktopTabsProps) => {
  const { t } = useTranslation();
  const { getClientId, getVaultStatus } = useAuthStore();
  const handleTimelineClick = () => {
    onTabChange(VAULT_TAB.MORTGAGE_TIMELINE);
    trackAmplitudeEvent(USER_EVENTS.MORTGAGE_TIMELINE.MORTGAGE_STATUS_CLICKED, {
      client_id: getClientId(),
      mortgage_status: getVaultStatus(),
    });
  };

  if (isVaultSubmitted) {
    return (
      <Flex direction='column' gap='4'>
        <Box w='100%' data-testid='mortgageTimelineBtn-container' display={ !isSpainBusiness ? 'block' : 'none' }>
          <NavbarTabButton
            isActive={ activeTab === VAULT_TAB.MORTGAGE_TIMELINE }
            onClick={ handleTimelineClick }
            icon={ (
              <Tasks
                color={ getColor(activeTab === VAULT_TAB.MORTGAGE_TIMELINE) }
              />
            ) }
            label={ t(`${baseTranslation}.mortgageTimelineBtn`) }
            dataTest='mortgageTimelineBtn-desktop'
          />
        </Box>
        <NavbarTabAccordion
          isActive={ activeTab === VAULT_TAB.FORMS || activeTab === VAULT_TAB.DOCUMENTS }
          onClick={ () => onTabChange(VAULT_TAB.FORMS) }
          icon={ <Folder color={ getColor(activeTab === VAULT_TAB.FORMS) } /> }
          label={ t(`${baseTranslation}.mortgageInfoBtn`) }
          dataTest='mortgageInfoBtn-desktop'
        >
          <NavbarTabButton
            isActive={ activeTab === VAULT_TAB.FORMS }
            onClick={ () => onTabChange(VAULT_TAB.FORMS) }
            icon={ <></> }
            label={ t(formLabel) }
            dataTest='formBtn-desktop'
            isVaultSubmitted={ isVaultSubmitted }
            isFormCompleted
          />
          <NavbarTabButton
            isActive={ activeTab === VAULT_TAB.DOCUMENTS }
            onClick={ () => onTabChange(VAULT_TAB.DOCUMENTS) }
            icon={ <></> }
            label={ t(documentLabel) }
            dataTest='documentBtn-desktop'
            isVaultSubmitted={ isVaultSubmitted }
          />
        </NavbarTabAccordion>
      </Flex>
    );
  }

  return (
    <Flex direction='column' gap='4'>
      <NavbarTabButton
        isActive={ activeTab === VAULT_TAB.FORMS }
        onClick={ () => onTabChange(VAULT_TAB.FORMS) }
        icon={ <FileText color={ getColor(activeTab === VAULT_TAB.FORMS) } /> }
        label={ t(formLabel) }
        dataTest='formBtn-desktop'
        description={ formDescription }
        isFormCompleted={ isFormCompleted }
      />
      <NavbarTabButton
        isActive={ activeTab === VAULT_TAB.DOCUMENTS }
        onClick={ () => onTabChange(VAULT_TAB.DOCUMENTS) }
        icon={ <Files color={ getColor(activeTab === VAULT_TAB.DOCUMENTS) } /> }
        label={ t(documentLabel) }
        dataTest='documentBtn-desktop'
      />
      <Box w='100%' display={ !isSpainBusiness ? 'block' : 'none' }>
        <Text
          mt='8'
          mb='1'
          size='md'
          fontWeight='medium'
          color='text-brand-tertiary'
        >
          {t(`${baseTranslation}.mortgageTimelineDesc`)}
        </Text>
        <NavbarTabButton
          isActive={ activeTab === VAULT_TAB.MORTGAGE_TIMELINE }
          onClick={ handleTimelineClick }
          icon={ (
            <Tasks
              color={ getColor(activeTab === VAULT_TAB.MORTGAGE_TIMELINE) }
            />
          ) }
          label={ t(`${baseTranslation}.mortgageTimelineBtn`) }
          dataTest='mortgageTimelineBtn-desktop'
        />
      </Box>
    </Flex>
  );
};
