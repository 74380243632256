import { useQuery } from '@tanstack/react-query';
import { APPLICANT_TYPE } from '@modules/documents/api/const';
import { Vault } from '@modules/documents/api/types';
import { documentsApi } from '@modules/documents/api';
import { useBrokerageStore } from '@modules/core/store';
import { useEffect } from 'react';

const recalibrateVaultData = (data: Vault) => {
  const mainApplicant = data?.applicants.find((app) => app.applicant_type === APPLICANT_TYPE.mainApplicant)!;
  return {
    mainApplicant: {
      firstName: mainApplicant.first_name,
      lastName: mainApplicant.last_name,
      email: mainApplicant.email,
    },
    applicants: data.applicants,
    brokerage: data.brokerage,
  };
};

const useVaultData = (opportunityId?: string | null, retry: boolean = true) => {
  const {
    data, isLoading, error, refetch, isRefetching,
  } = useQuery({
    queryKey: ['vault', opportunityId],
    queryFn: ({ queryKey }) => documentsApi.getVault(queryKey[1]!),
    select: (queryData) => recalibrateVaultData(queryData),
    retry,
    enabled: !!opportunityId,
    refetchInterval: !retry ? false : 10000,
    staleTime: !retry ? Number.POSITIVE_INFINITY : 1_800_000,
  });

  const { setBrokerage } = useBrokerageStore();
  useEffect(() => {
    if (data?.brokerage) {
      setBrokerage(data.brokerage);
    }
  }, [data?.brokerage, setBrokerage]);

  return {
    data: data ?? {
      applicants: [],
      mainApplicant: {
        firstName: '',
        lastName: '',
      },
      brokerage: null,
    },
    isLoading: isLoading || !data,
    error,
    refetch,
    isRefetching,
  };
};

export default useVaultData;
