import { Drawer } from '@mantine/core';
import { Box, Flex } from '@huspy/briks-web';
import { Divider } from '@huspy/styled-system/jsx';
import { Cancel } from '@huspy/briks-icons';
import { useMediaQuery } from '@mantine/hooks';
import { maxMd } from '@shared/css.const';

type BaseSlidoProps = {
  opened: boolean;
  close: () => void;
  title: React.ReactNode;
  children: React.ReactNode;
  footer: React.ReactNode;
};
export const BaseSlido = ({
  opened,
  close,
  title,
  children,
  footer,
}: BaseSlidoProps) => {
  const matches = useMediaQuery(maxMd);
  const bodyHeight = `calc(100% - ${matches ? '65' : '101'}px)`;
  return (
    <Drawer.Root
      opened={ opened }
      onClose={ close }
      position={ matches ? 'bottom' : 'right' }
      size='lg'
    >
      <Drawer.Overlay />
      <Drawer.Content bg='white'>
        <Drawer.Header p='0'>
          <Drawer.Title style={ { width: '100%' } }>
            <Box p='8'>

              {title}
            </Box>
          </Drawer.Title>
          <Flex
            justify='center'
            align='center'
            w='11'
            h='11'
            mr='8'
            cursor='pointer'
            boxShadow='shadow-4'
            borderRadius='full'
            onClick={ close }
          >
            <Cancel height={ 20 } width={ 20 } />
          </Flex>
        </Drawer.Header>
        <Divider />
        <Drawer.Body p='0' h={ bodyHeight }>
          <Flex
            direction='column'
            pos='relative'
            justify='space-between'
            h='100%'
          >
            <Box p='8'>{children}</Box>
            <Box
              position='sticky'
              bottom='0'
              bg='base.white'
              w='100%'
              px='8'
              py='4'
              borderTop='1px solid'
              borderColor='neutral.100'
            >
              {footer}
            </Box>
          </Flex>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};
