import documentsEvents from './documentsEvents';
import formFieldsEvents from './formFieldsEvents';
import proposalsEvents from './proposalsEvents';
import authEvents from './authEvents';
import mortgageTimelineEvents from './mortgageTimelineEvents';

export const USER_EVENTS = {
  PROPOSAL: proposalsEvents,
  FORM_FIELDS: formFieldsEvents,
  DOCUMENTS: documentsEvents,
  AUTH: authEvents,
  MORTGAGE_TIMELINE: mortgageTimelineEvents,
};
