import { c as O, t as $ } from "./utils-CF-Lje7U.mjs";
import { z as s } from "zod";
import { t as c } from "i18next";
import "@mantine/notifications";
import "react";
import "./vanilla-LyKl0mEw.mjs";
import "@mantine/core";
import "./createReactComponent-Btb2fexK.mjs";
import "clsx";
class D {
  constructor(e) {
    this.fetcher = e;
  }
  async get(e, r, t, a, l) {
    let f = e;
    if (r) {
      const d = new URLSearchParams();
      Object.keys(r).forEach((m) => {
        const o = r[m];
        Array.isArray(o) ? o.forEach((u) => {
          u != null && d.append(m, u);
        }) : o != null && d.append(m, o);
      }), f = `${e}?${d.toString()}`;
    }
    return this.fetcher.request(
      f,
      "GET",
      null,
      t,
      a,
      l
    );
  }
  async post(e, r, t, a, l) {
    return this.fetcher.request(e, "POST", r, t, a, l);
  }
  async update(e, r, t, a) {
    return this.fetcher.request(e, "PUT", r, t, a);
  }
  async delete(e, r, t, a) {
    return this.fetcher.request(e, "DELETE", null, t, a);
  }
  async patch(e, r, t, a) {
    return this.fetcher.request(e, "PATCH", r, t, a);
  }
}
class H {
}
const i = c("common.validation.requiredField"), p = c("common.validation.invalidValue"), g = c("common.validation.invalidEmail"), h = s.string({
  errorMap: (n, e) => e.data === null ? { message: i } : { message: e.defaultError }
}).min(1, { message: i }).max(100), E = s.string({ required_error: i }).email({ message: g }).min(1, { message: i }).max(100), v = s.number({
  errorMap: (n, e) => !e.data || e.data === "" ? { message: i } : { message: e.defaultError }
}).nonnegative(), F = s.coerce.number({
  errorMap: (n, e) => !e.data || e.data === "" ? { message: i } : { message: e.defaultError }
}).nonnegative(), V = s.date({ required_error: i }).min(new Date(1850, 1, 1)).max(/* @__PURE__ */ new Date()), q = (n) => s.string().refine((e) => n.includes(e), { message: p }), L = {
  textFieldValidator: h,
  emailFieldValidator: E,
  numberFieldValidator: v,
  numberCoerceFieldValidator: F,
  dateFieldValidator: V,
  enumFieldValidator: q
};
export {
  H as Fetcher,
  D as HttpClient,
  O as capturePdfFrameIntoHTML,
  $ as toast,
  L as validators
};
