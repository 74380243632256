import { usePublicVaultData } from '@modules/core/hooks/queries/usePublicVaultData';
import useGetOpportunityId from '@modules/core/hooks/useGetOpportunityId';
import { Navigate } from 'react-router-dom';
import { useAuthStore, useBrokerageStore } from '@modules/core/store';
import usePersistAuth from '@modules/core/hooks/usePersistAuth';
import { useHandleLanguage } from '@modules/core/hooks/useHandleLanguage';
import { useEffect } from 'react';
import { identifyAmplitudeSession, trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { OtpAuthFlow } from './OtpFlow';

export const Authentication = () => {
  const oppId = useGetOpportunityId();
  const {
    data: publicData,
    error: publicDataError,
    isLoading,
  } = usePublicVaultData(oppId);
  const { token, setClientId } = useAuthStore();
  const { setBrokerId } = useBrokerageStore();
  const { tokenFromSessionStorage } = usePersistAuth();
  const brokerExternalId = publicData?.broker.auth_external_id;
  const clientExternalId = publicData?.main_applicant.client_external_id;

  useHandleLanguage();

  useEffect(() => {
    if (oppId && publicData && !token) {
      identifyAmplitudeSession({
        oppId,
        brokerId: brokerExternalId ?? '',
      });
      if (brokerExternalId) {
        setBrokerId(brokerExternalId);
      }
      if (clientExternalId) {
        setClientId(clientExternalId);
      }
      trackAmplitudeEvent(
        USER_EVENTS.AUTH.VAULT_LOGIN_PAGE_VIEWED,
        { client_id: publicData.main_applicant.client_external_id }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oppId, publicData]);

  if (tokenFromSessionStorage && token && oppId) {
    return <Navigate to={ `/${oppId}` } />;
  }

  if (isLoading) {
    return <></>;
  }

  if (publicDataError?.statusCode === 404) {
    return <Navigate to='/' />;
  }

  return (
    <OtpAuthFlow
      maskedEmail={ publicData?.main_applicant.masked_email ?? '' }
      logo={ publicData?.brokerage.logo }
    />
  );
};
