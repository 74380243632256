import {
  BANK_APPLICATION_STATUS,
  BankApplicationStatusType,
  BankApplicationSubStatusType,
  BANK_APPLICATION_SUB_STATUS,
} from '@modules/core/api/types';
import { t } from 'i18next';

type StatusType = BankApplicationStatusType | BankApplicationSubStatusType;

type MortgageTimeline = {
  order: number;
  statuses: StatusType[];
  title: string;
  description: string;
};
const baseTranslation = 'mortgageTimeline.bankApplicationStatus';

export const mortgageTimeline: MortgageTimeline[] = [
  {
    order: 1,
    statuses: [
      BANK_APPLICATION_STATUS.draft,
      BANK_APPLICATION_STATUS.proposal,
      BANK_APPLICATION_STATUS.docsCollection
    ],
    title: t(`${baseTranslation}.documentCollection.title`),
    description: t(`${baseTranslation}.documentCollection.description`),
  },
  {
    order: 2,
    statuses: [
      BANK_APPLICATION_STATUS.docsReview,
      BANK_APPLICATION_STATUS.fillForms
    ],
    title: t(`${baseTranslation}.documentReview.title`),
    description: t(`${baseTranslation}.documentReview.description`),
  },
  {
    order: 3,
    statuses: [BANK_APPLICATION_STATUS.docsReadyToSend],
    title: t(`${baseTranslation}.preparingToSend.title`),
    description: t(`${baseTranslation}.preparingToSend.description`),
  },
  {
    order: 4,
    statuses: [
      BANK_APPLICATION_STATUS.bankSubmission,
      BANK_APPLICATION_STATUS.salesQueries,
      BANK_APPLICATION_STATUS.salesSubmittedToCredit,
      BANK_APPLICATION_STATUS.creditQueries
    ],
    title: t(`${baseTranslation}.bankProcessing.title`),
    description: t(`${baseTranslation}.bankProcessing.description`),
  },
  {
    order: 5,
    statuses: [BANK_APPLICATION_STATUS.preApproved],
    title: t(`${baseTranslation}.preApprovalReceived.title`),
    description: t(`${baseTranslation}.preApprovalReceived.description`),
  },
  {
    order: 6,
    statuses: [BANK_APPLICATION_STATUS.propertySelection],
    title: t(`${baseTranslation}.waitingForProperty.title`),
    description: t(`${baseTranslation}.waitingForProperty.description`),
  },
  {
    order: 7,
    statuses: [BANK_APPLICATION_STATUS.valuation],
    title: t(`${baseTranslation}.valuationInProcess.title`),
    description: t(`${baseTranslation}.valuationInProcess.description`),
  },
  {
    order: 8,
    statuses: [
      BANK_APPLICATION_STATUS.fol,
      BANK_APPLICATION_SUB_STATUS.requested,
      BANK_APPLICATION_SUB_STATUS.queries,
      BANK_APPLICATION_SUB_STATUS.queriesResponded

    ],
    title: t(`${baseTranslation}.folInProcess.title`),
    description: t(`${baseTranslation}.folInProcess.description`),
  },
  {
    order: 9,
    statuses: [
      BANK_APPLICATION_STATUS.fol,
      BANK_APPLICATION_SUB_STATUS.received
    ],
    title: t(`${baseTranslation}.folReceived.title`),
    description: t(`${baseTranslation}.folReceived.description`),
  },
  {
    order: 10,
    statuses: [BANK_APPLICATION_STATUS.folSigned],
    title: t(`${baseTranslation}.folSigned.title`),
    description: t(`${baseTranslation}.folSigned.description`),
  },
  {
    order: 11,
    statuses: [BANK_APPLICATION_STATUS.finalDocs],
    title: t(`${baseTranslation}.preparingForDisbursal.title`),
    description: t(`${baseTranslation}.preparingForDisbursal.description`),
  },
  {
    order: 12,
    statuses: [BANK_APPLICATION_STATUS.disbursed],
    title: t(`${baseTranslation}.loanDisbursed.title`),
    description: t(`${baseTranslation}.loanDisbursed.description`),
  },
  {
    order: 13,
    statuses: [BANK_APPLICATION_STATUS.propertyTransferred],
    title: t(`${baseTranslation}.propertyTransferred.title`),
    description: t(`${baseTranslation}.propertyTransferred.description`),
  }
];
