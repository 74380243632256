import { Flex } from '@huspy/briks-web';
import { useState } from 'react';
import { Image } from '@mantine/core';
import { imagesPath } from '@shared/css.const';
import {
  leftResponsiveSectionStyles,
  responsiveContainerStyles,
  rightResponsiveSectionStyles,
} from './styles/index.css';
import { RequestOtp } from './widgets/RequestOtp';
import { VerifyOtp } from './widgets/VerifyOtp';

export const OtpAuthFlow = ({
  logo,
  maskedEmail,
}: {
  logo: string | undefined;
  maskedEmail: string;
}) => {
  const [session, setSession] = useState<null | string>(null);
  const [isOtpRequested, setIsOtpRequested] = useState(false);

  return (
    <Flex
      className={ responsiveContainerStyles }
      justify='center'
      align='center'
      h='100vh'
      data-testid='otp-auth-screen'
    >
      <Flex
        className={ leftResponsiveSectionStyles }
        justify='center'
        align='center'
        h='100%'
        bg='blue.100'
      >
        <Flex
          h='275'
          w='275'
          borderRadius='5'
          bg='base.white'
          shadow='shadow-4'
          overflow='hidden'
          p='4'
        >
          <Image
            src={ logo }
            fallbackSrc={ `${imagesPath}/vault.png` }
            style={ { objectFit: 'contain' } }
          />
        </Flex>
      </Flex>
      <Flex
        className={ rightResponsiveSectionStyles }
        justify='center'
        align='center'
      >
        {!isOtpRequested && (
          <RequestOtp
            setSession={ (_session: string) => setSession(_session) }
            maskedEmail={ maskedEmail }
            setIsOtpRequested={ () => setIsOtpRequested(true) }
          />
        )}
        {isOtpRequested && (
          <VerifyOtp
            session={ session }
            setSession={ (_session: string | null) => setSession(_session) }
          />
        )}
      </Flex>
    </Flex>
  );
};
