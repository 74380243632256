import { DocumentCategory } from '@modules/documents/api/types';
import { BUSINESS_IDENTIFIER } from './const';

type VaultBusinessIdentifier = typeof BUSINESS_IDENTIFIER[keyof typeof BUSINESS_IDENTIFIER];

type DocumentsProgress = {
  total: number;
  valid: number;
  processing: number;
  percentage: number;
  uploaded: number;
  uploaded_percentage: number;
  invalid: number;
};

type KycProgress = {
  total: number;
  count: number;
  percentage: number;
  disabled: boolean;
};

export type ApplicantProgressDocs = DocumentsProgress &
{ progress_per_category: Record<DocumentCategory, DocumentsProgress> };

export type ApplicantProgressKyc = KycProgress & { progress_per_category:
Record<'personal_information' | 'residential_information' | 'employment_information', KycProgress> };

export type VaultKYCProgress = KycProgress & { progress_per_applicant: Record<string, ApplicantProgressKyc> };

export type VaultDocumentProgress = DocumentsProgress & { progress_per_applicant: Record<string, ApplicantProgressDocs> };

export type VaultOverview = {
  business_identifier: VaultBusinessIdentifier,
  progress: {
    kyc_progress: VaultKYCProgress,
    document_progress: VaultDocumentProgress
  }
};

export type DirectFeatureFlagResponse = {
  [flagKey: string]: {
    key: string;
  }
};

export const AMPLITUDE_FEATURE_FLAGS = {
  CLIENT_VAULT_MAINTENANCE_MODE: 'client-vault-maintenance-mode',
  CLIENT_VAULT_AUTO_SAVE: 'client-vault-auto-save',
} as const;

export type PublicVaultData = {
  brokerage: { logo: string };
  main_applicant: { masked_email: string, client_external_id: string };
  broker: { auth_external_id: string };
};

export const BANK_APPLICATION_SUB_STATUS = {
  partialDocs: 'Partial Docs',
  fullDocs: 'Full Docs',
  queriesResolved: 'Queries Resolved',
  queriesResponded: 'Queries Responded',
  requested: 'Requested',
  initiated: 'Initiated',
  received: 'Received',
  queries: 'Queries',
  formSent: 'Form Sent',
} as const;

export const BANK_APPLICATION_STATUS = {
  draft: 'draft',
  proposal: 'proposal',
  docsCollection: 'docs_collection',
  docsReview: 'docs_review',
  fillForms: 'fill_forms',
  docsReadyToSend: 'docs_ready_to_send',
  bankSubmission: 'bank_submission',
  salesQueries: 'sales_queries',
  salesSubmittedToCredit: 'sales_submitted_to_credit',
  creditQueries: 'credit_queries',
  preApproved: 'pre_approved',
  propertySelection: 'property_selection',
  valuation: 'valuation',
  fol: 'fol',
  folSigned: 'fol_signed',
  finalDocs: 'final_docs',
  notProceeding: 'not_proceeding',
  onHold: 'on_hold',
  approved: 'approved',
  declined: 'declined',
  disbursed: 'disbursed',
  propertyTransferred: 'property_transferred',
} as const;

export const OPPORTUNITY_STATUS = {
  draft: 'Draft',
  new: 'New',
  inProgress: 'In-Progress',
  lost: 'Closed Lost',
  onHold: 'On Hold',
  // frontend status
  notAvailable: 'not_available',
} as const;

export const BANK_SUBMISSION_TYPE = {
  DIRECT_TO_BANK: 'direct-to-bank',
  SUBMIT_THROUGH_HUSPY: 'submit-through-huspy',
} as const;

export type OpportunityStatusType =
  (typeof OPPORTUNITY_STATUS)[keyof typeof OPPORTUNITY_STATUS];

export type BankApplicationStatusType =
  (typeof BANK_APPLICATION_STATUS)[keyof typeof BANK_APPLICATION_STATUS];

export type BankApplicationSubStatusType =
  (typeof BANK_APPLICATION_SUB_STATUS)[keyof typeof BANK_APPLICATION_SUB_STATUS];

export type StatusHistory = {
  status: BankApplicationStatusType;
  timestamp: string;
};

export type BankSubmissionType =
  (typeof BANK_SUBMISSION_TYPE)[keyof typeof BANK_SUBMISSION_TYPE];

type BankApplication = {
  bank_application_external_id: string;
  opportunity_external_id: string;
  created_at: string;
  updated_at: string;
  status: BankApplicationStatusType;
  sub_status: BankApplicationSubStatusType | null;
  is_primary: boolean;
  status_history: StatusHistory[];
  bank_submission_channel_type: BankSubmissionType | null;
};

export type MortgageTimeline = {
  opportunity_external_id: string;
  status: OpportunityStatusType;
  property_status: string;
  bank_applications: BankApplication[];
  created_at: string;
  updated_at: string;
};
