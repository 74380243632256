import { coreApi } from '@modules/core/api';
import { useAuthStore } from '@modules/core/store';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

export const useGetMortgageTimeline = (opportunityId: string | undefined) => {
  const { setVaultStatus } = useAuthStore();
  const { data, isLoading, error } = useQuery({
    queryKey: ['mortgageTimeline', opportunityId],
    queryFn: () => coreApi.getMortgageTimeline(opportunityId!),
    enabled: !!opportunityId,
    retry: false,
  });

  useEffect(() => {
    if (data?.opportunity) {
      const status = data.opportunity.bank_applications.length > 0
        ? data.opportunity.bank_applications[0]?.status!
        : data.opportunity.status;
      setVaultStatus(status);
    }
  }, [data, setVaultStatus]);

  return {
    data,
    isLoading,
    error,
  };
};
