import {
  AppShell, Image, Skeleton
} from '@mantine/core';
import '@mantine/core/styles/AppShell.css';
import {
  Box, Flex, Text
} from '@huspy/briks-web';

import { useTranslation } from 'react-i18next';
import { imagesPath } from '@shared/css.const';
import { contentWrapper } from '../styles.css';
import { Header } from './Header';

const ErrorComponent = () => (
  <Flex
    w='100%'
    h='100%'
    bg='white'
    p='4'
    borderRadius='4'
    borderWidth='1px'
    borderStyle='solid'
    borderColor='neutral.200'
    mt='6'
  >
    <Flex
      w='100%'
      h='100%'
      direction='column'
      justify='center'
      align='center'
      gap='4'
    >
      <Flex
        h='240px'
        w='100%'
        direction='column'
        justify='center'
        align='center'
        bg='neutral.100'
        borderRadius='4'
        borderWidth='1px'
        borderStyle='solid'
        borderColor='neutral.200'
        position='relative'
      >
        <Image
          width='120px'
          height='120px'
          src={ `${imagesPath}/question.png` }
        />
      </Flex>
      <Text size='3xl' color='text-primary' fontWeight='medium'>
        We’re unable to load your forms
      </Text>
      <Text size='2xl' color='text-secondary'>
        Please try refreshing the page or contact your mortgage consultant for assistance.
      </Text>
    </Flex>
  </Flex>
);

const LoadingComponent = () => (
  <Box my='8' w='100%'>
    <Skeleton visible h='calc(100vh - 280px)' radius='lg' />
  </Box>
);

export const VaultAppShellFallback = ({ status }: { status: 'error' | 'loading' }) => {
  const { t } = useTranslation();

  return (
    <AppShell
      header={ { height: 84 } }
      navbar={ {
        width: { base: 300, md: 300, lg: 367 },
        breakpoint: '993',
        collapsed: { mobile: true },
      } }
    >
      <AppShell.Header>
        <Header
          logoutLabel={ t('home.header.logout') }
          isAutoSaveLoading
          autoSaveHasError
          hasErrorOrLoading
          isVaultSubmitted={ false }
        />
      </AppShell.Header>
      <AppShell.Navbar />
      <AppShell.Main bg='#F9FAFB'>
        <Box className={ contentWrapper }>
          {status === 'loading' && <LoadingComponent />}
          {status === 'error' && <ErrorComponent />}
        </Box>
      </AppShell.Main>
      <AppShell.Footer
        bg='rgba(256,256, 256, 80%)'
        style={ { backdropFilter: 'blur(8px)' } }
      >
        <></>
      </AppShell.Footer>
    </AppShell>
  );
};
