import { mergeMantineTheme as E, DEFAULT_THEME as C, em as x, rem as n, createTheme as y } from "@mantine/core";
function m(o, t, f) {
  return Object.keys(o[t]).reduce((h, g) => (h[g] = `var(--mantine-${f}-${g})`, h), {});
}
function v(o, t) {
  return o in t.breakpoints ? t.breakpoints[o] : x(o);
}
function z(o) {
  const t = E(C, o), f = m(t, "fontSizes", "font-size"), h = m(t, "lineHeights", "line-height"), g = m(t, "shadows", "shadow"), p = m(t, "radius", "radius"), d = m(t, "spacing", "spacing"), u = Object.keys(t.headings.sizes).reduce(
    (a, e) => (a[e] = {
      fontSize: `var(--mantine-${e}-font-size)`,
      lineHeight: `var(--mantine-${e}-line-height)`,
      fontWeight: `var(--mantine-${e}-font-weight)`
    }, a),
    {}
  ), S = Object.keys(t.colors).reduce(
    (a, e) => (a[e] = {
      0: `var(--mantine-color-${e}-0)`,
      1: `var(--mantine-color-${e}-1)`,
      2: `var(--mantine-color-${e}-2)`,
      3: `var(--mantine-color-${e}-3)`,
      4: `var(--mantine-color-${e}-4)`,
      5: `var(--mantine-color-${e}-5)`,
      6: `var(--mantine-color-${e}-6)`,
      7: `var(--mantine-color-${e}-7)`,
      8: `var(--mantine-color-${e}-8)`,
      9: `var(--mantine-color-${e}-9)`,
      filled: `var(--mantine-color-${e}-filled)`,
      filledHover: `var(--mantine-color-${e}-filled-hover)`,
      light: `var(--mantine-color-${e}-light)`,
      lightHover: `var(--mantine-color-${e}-light-hover)`,
      lightColor: `var(--mantine-color-${e}-light-color)`,
      outline: `var(--mantine-color-${e}-outline)`,
      outlineHover: `var(--mantine-color-${e}-outline-hover)`
    }, a),
    {
      primary: "var(--mantine-primary-color-filled)",
      primaryColors: {
        0: "var(--mantine-primary-color-0)",
        1: "var(--mantine-primary-color-1)",
        2: "var(--mantine-primary-color-2)",
        3: "var(--mantine-primary-color-3)",
        4: "var(--mantine-primary-color-4)",
        5: "var(--mantine-primary-color-5)",
        6: "var(--mantine-primary-color-6)",
        7: "var(--mantine-primary-color-7)",
        8: "var(--mantine-primary-color-8)",
        9: "var(--mantine-primary-color-9)",
        filled: "var(--mantine-primary-color-filled)",
        filledHover: "var(--mantine-primary-color-filled-hover)",
        light: "var(--mantine-primary-color-light)",
        lightHover: "var(--mantine-primary-color-light-hover)",
        lightColor: "var(--mantine-primary-color-light-color)",
        outline: "var(--mantine-primary-color-outline)",
        outlineHover: "var(--mantine-primary-color-outline-hover)"
      },
      white: "var(--mantine-color-white)",
      black: "var(--mantine-color-black)",
      text: "var(--mantine-color-text)",
      body: "var(--mantine-color-body)",
      error: "var(--mantine-color-error)",
      placeholder: "var(--mantine-color-placeholder)",
      anchor: "var(--mantine-color-anchor)",
      default: "var(--mantine-color-default)",
      defaultHover: "var(--mantine-color-default-hover)",
      defaultColor: "var(--mantine-color-default-color)",
      defaultBorder: "var(--mantine-color-default-border)",
      dimmed: "var(--mantine-color-dimmed)"
    }
  );
  return {
    scale: "var(--mantine-scale)",
    cursorType: "var(--mantine-cursor-type)",
    webkitFontSmoothing: "var(--mantine-webkit-font-smoothing)",
    mozFontSmoothing: "var(--mantine-moz-font-smoothing)",
    lineHeight: "var(--mantine-line-height)",
    fontFamily: "var(--mantine-font-family)",
    fontFamilyMonospace: "var(--mantine-font-family-monospace)",
    fontFamilyHeadings: "var(--mantine-font-family-headings)",
    headingFontWeight: "var(--mantine-heading-font-weight)",
    radiusDefault: "var(--mantine-radius-default)",
    breakpoints: t.breakpoints,
    fontSizes: f,
    lineHeights: h,
    shadows: g,
    radius: p,
    headings: u,
    spacing: d,
    colors: S,
    rtlSelector: '[dir="rtl"] &',
    darkSelector: '[data-mantine-color-scheme="dark"] &',
    lightSelector: '[data-mantine-color-scheme="light"] &',
    smallerThan: (a) => `(max-width: ${v(a, t)})`,
    largerThan: (a) => `(min-width: ${v(a, t)})`
  };
}
const s = {
  xl: {
    fontSize: n(24),
    fontWeight: 400,
    letterSpacing: 0.02,
    lineHeight: "135%"
  },
  lg: {
    fontSize: n(20),
    fontWeight: 400,
    letterSpacing: -0.01,
    lineHeight: "130%"
  },
  md: {
    fontSize: n(16),
    fontWeight: 400,
    letterSpacing: -0.01,
    lineHeight: "135%"
  },
  sm: {
    fontSize: n(14),
    fontWeight: 400,
    letterSpacing: -0.01,
    lineHeight: "130%"
  },
  xs: {
    fontSize: n(12),
    fontWeight: 400,
    letterSpacing: -0.01,
    lineHeight: "120%"
  }
}, c = {
  h1: {
    fontSize: n(64),
    fontWeight: 600,
    letterSpacing: -0.4,
    lineHeight: "100%"
  },
  h2: {
    fontSize: n(52),
    fontWeight: 600,
    letterSpacing: 0.04,
    lineHeight: "100%"
  },
  h3: {
    fontSize: n(40),
    fontWeight: 600,
    letterSpacing: -0.4,
    lineHeight: "100%"
  },
  h4: {
    fontSize: n(28),
    fontWeight: 600,
    letterSpacing: -0.4,
    lineHeight: "100%"
  },
  h5: {
    fontSize: n(20),
    fontWeight: 600,
    letterSpacing: -0.4,
    lineHeight: "100%"
  }
};
n(136), n(96);
const D = {
  100: "#EEF2FD",
  200: "#B9CCF6",
  300: "#97B3F1",
  400: "#7499ED",
  500: "#5180E8",
  600: "#225EE2",
  700: "#1B53CF",
  800: "#184AB9",
  900: "#1541A2"
}, A = {
  100: "#EAF8ED",
  200: "#ABE3B9",
  300: "#82D495",
  400: "#58C672",
  500: "#2EB84F",
  600: "#29A346",
  700: "#248F3D",
  800: "#1F7A35",
  900: "#145223"
}, H = {
  100: "#FFFFFF",
  200: "#FAFAFA",
  300: "#F5F5F5",
  400: "#E5E5E5",
  500: "#CCCCCC",
  600: "#999999",
  700: "#666666",
  800: "#333333",
  900: "#000000"
}, $ = {
  100: "#FFE6DA",
  200: "#FFCCB6",
  300: "#FFB391",
  400: "#FF996D",
  500: "#FF8048",
  600: "#FF5C14",
  700: "#FA4C00",
  800: "#E04500",
  900: "#C73D00"
}, b = {
  100: "#FFEBEB",
  200: "#FFD6D6",
  300: "#FFC2C2",
  400: "#FFADAD",
  500: "#FF9999",
  600: "#FF8080",
  700: "#FF6666",
  800: "#FF4C4C",
  900: "#FF1919"
}, B = {
  100: "#F1EDFD",
  200: "#C6B9F7",
  300: "#AA96F2",
  400: "#8D73EE",
  500: "#7150EA",
  600: "#603BE7",
  700: "#4E24E5",
  800: "#421AD6",
  900: "#3515AD"
}, w = {
  100: "#FEFEFD",
  200: "#FCFAF6",
  300: "#F7F2E9",
  400: "#F0E7D6",
  500: "#E9DCC3",
  600: "#E2D1B0",
  700: "#DCC69E",
  800: "",
  900: "#DCC69E"
}, k = {
  100: "#FEF6DA",
  200: "#FCEEB6",
  300: "#FBE591",
  400: "#F9DD6D",
  500: "#F8D448",
  600: "#F6C918",
  700: "#ECBD09",
  800: "#D3AA08",
  900: "#BB9607"
}, W = {
  500: "#2EC4A0",
  900: "#178268",
  100: "#EAF9F6"
}, T = {
  500: "#E56F43",
  900: "#9D3A16",
  100: "#FCF1EC"
}, j = {
  500: "#E33C5A",
  900: "#A11730",
  100: "#FCECEF"
}, i = {
  purple: B,
  blue: D,
  green: A,
  neutral: H,
  sand: w,
  orange: $,
  pink: b,
  yellow: k,
  positive: W,
  warning: T,
  negative: j
};
function r(o) {
  return Object.values(o);
}
const O = {
  neutral: r(i.neutral),
  sand: r(i.sand),
  blue: r(i.blue),
  purple: r(i.purple),
  green: r(i.green),
  positive: r(i.positive),
  negative: r(i.negative),
  warning: r(i.warning),
  orange: r(i.orange),
  pink: r(i.pink),
  yellow: r(i.yellow)
}, l = {
  xs: 4,
  sm: 8,
  md: 12,
  lg: 16,
  xl: 24,
  "2xl": 32,
  "3xl": 40
}, F = {
  1: "0px 2px 10px 0px rgba(0, 0, 0, 0.08)",
  2: "0px 4px 16px 0px rgba(0, 0, 0, 0.16)",
  3: "0px 6px 32px 0px rgba(0, 0, 0, 0.16)"
}, M = y({
  colors: O,
  shadows: {
    1: F[1],
    2: F[1],
    3: F[3]
  },
  breakpoints: { xxl: "112.5em" },
  spacing: {
    xs: n(l.xs),
    sm: n(l.sm),
    md: n(l.md),
    lg: n(l.lg),
    xl: n(l.xl),
    "2xl": n(l["2xl"]),
    "3xl": n(l["3xl"])
  },
  primaryColor: "neutral",
  fontFamily: "Telegraf",
  fontSizes: {
    xl: s.xl.fontSize,
    lg: s.lg.fontSize,
    md: s.md.fontSize,
    sm: s.sm.fontSize,
    xs: s.xs.fontSize
  },
  headings: {
    sizes: {
      h1: { fontSize: c.h1.fontSize },
      h2: { fontSize: c.h2.fontSize },
      h3: { fontSize: c.h3.fontSize },
      h4: { fontSize: c.h4.fontSize },
      h5: { fontSize: c.h5.fontSize }
    }
  }
}), _ = z(M);
export {
  M as _,
  _ as t
};
