import { API_V2_URL } from '@shared/const';
import http from '@shared/http';
import {
  KYCApplicant,
  KYCCountry,
  KYCData,
  UpdateKycData,
  SubmitVault
} from '@huspy/forge/types';

export const kycApi = {
  getKYCData: <TCountry extends KYCCountry = 'AE'>(opportunityId: string) =>
    http.get<KYCData<TCountry>>(`${API_V2_URL}/vaults/${opportunityId}/applicants`),
  getBanks: () =>
    http.get<{
      external_id: string;
      logo: string;
      title: string;
    }[]>(`${API_V2_URL}/vaults/banks`),
  updateKYCData:
    <TCountry extends KYCCountry = 'AE'>(opportunityId: string, applicantId: string, body: UpdateKycData<TCountry>) =>
      http.post(`${API_V2_URL}/vaults/${opportunityId}/applicants/${applicantId}`, body),
  submitVault: <TCountry extends KYCCountry = 'AE'>(
    opportunityId: string,
    body: SubmitVault<TCountry>
  ) =>
    http.post(
      `${API_V2_URL}/vaults/${opportunityId}/applicants/bulk`,
      body
    ),
  getVaultStorage: (opportunityId: string) =>
    http.get<{
      vault_data_json: { applicants: KYCApplicant[] },
      vault_status: 'draft' | 'submitted' | 'disabled'
    }>(`${API_V2_URL}/vaults/${opportunityId}/vault_storage`),
  createVaultStorage: (
    opportunityId: string
  ) =>
    http.post(`${API_V2_URL}/vaults/${opportunityId}/vault_storage`, null),
  updateVaultStorage: (
    opportunityId: string,
    data: UpdateKycData<KYCCountry>[]
  ) =>
    http.update(
      `${API_V2_URL}/vaults/${opportunityId}/vault_storage`,
      { applicants: data }
    ),
} as const;
