import { Check } from '@huspy/briks-icons';
import { Flex } from '@huspy/briks-web';
import { imagesPath } from '@shared/css.const';
import { Image } from '@mantine/core';
import * as styles from './styles.css';

const NestedCircles = ({
  className,
  children,
}: {
  className: string;
  children?: React.ReactNode;
}) => <Flex className={ `${styles.circle} ${className}` }>{children}</Flex>;
export const StatusIcon = ({
  isCompleted,
  isCurrent,
  isLast,
}: {
  isCompleted: boolean;
  isCurrent: boolean;
  isLast: boolean;
}) => {
  if (isLast) {
    return (
      <Flex position='absolute' right='-27px' top='-25px' w='16' h='16'>
        <Image
          width='100%'
          height='100%'
          src={ `${imagesPath}/small-house.svg` }
        />
      </Flex>
    );
  }
  if (isCompleted) {
    return (
      <Flex
        bg='linear-gradient(180deg, #06A36F 0%, #10D0CC 100%)'
        borderRadius='50%'
        p='1'
        data-testid='test-data-completed'
      >
        <Check color='white' width='24px' height='24px' />
      </Flex>
    );
  }
  if (isCurrent) {
    return (
      <Flex
        position='absolute'
        justify='center'
        align='center'
        borderRadius='50%'
      >
        <Flex
          position='absolute'
          justify='center'
          align='center'
          borderRadius='50%'
        >
          <NestedCircles className={ styles.large }>
            <NestedCircles className={ styles.medium }>
              <NestedCircles className={ styles.small } />
            </NestedCircles>
          </NestedCircles>
        </Flex>
      </Flex>
    );
  }
};
