import { useMutation } from '@tanstack/react-query';
import { kycApi } from '@modules/kyc/api';

export const useCreateVaultStorage = (opportunityId: string) => {
  const { mutateAsync, error, isPending } = useMutation({
    mutationKey: ['create-vault-storage', opportunityId],
    mutationFn: () => kycApi.createVaultStorage(opportunityId),
  });

  return { error, mutateAsync, isPending };
};
