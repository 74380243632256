import { AlertTriangle, CheckCircle2 } from '@huspy/briks-icons';
import { Flex, Text } from '@huspy/briks-web';
import briksTheme from '@shared/briksTheme';
import { useTranslation } from 'react-i18next';

export const VaultSubmittedBanner = ({
  isVaultSubmitted,
  isVaultDisabled,
}: {
  isVaultSubmitted: boolean;
  isVaultDisabled: boolean;
}) => {
  const { t } = useTranslation();
  if (!isVaultSubmitted && !isVaultDisabled) return null;
  if (isVaultDisabled) {
    return (
      <Flex
        bg='utility-blue-100'
        direction='column'
        px='4'
        py='2'
        gap='1'
        borderRadius='2'
        marginBottom='8'
      >
        <Flex gap='2' align='center'>
          <Text size='lg' color='text-secondary' fontWeight='medium'>
            {t('kyc.disabledForm.title')}
          </Text>
          <AlertTriangle color={ briksTheme.colors['neutral.500'] } />
        </Flex>
        <Text size='sm'>{t('kyc.disabledForm.description')}</Text>
      </Flex>
    );
  }
  return (
    <Flex
      bg='bg-success-primary'
      direction='column'
      px='4'
      py='2'
      gap='1'
      borderRadius='2'
      marginBottom='8'
    >
      <Flex gap='2' align='center'>
        <Text size='lg' color='text-success-primary_alt' fontWeight='medium'>
          {t('kyc.submittedForm.title')}
        </Text>
        <CheckCircle2 color={ briksTheme.colors['success.500'] } />
      </Flex>
      <Text size='sm'>{t('kyc.submittedForm.description')}</Text>
    </Flex>
  );
};
