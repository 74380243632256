import { Flex, Text } from '@huspy/briks-web';
import { useTranslation } from 'react-i18next';
import { Image } from '@mantine/core';
import { imagesPath } from '@shared/css.const';
import { Check } from '@huspy/briks-icons';
import { Divider } from '@huspy/styled-system/jsx';
import { ReactNode } from 'react';
import { cardContainer } from '../styles.css';

const DocumentIcon = () => (
  <Flex
    bg='neutral.100'
    borderRadius='2'
    borderWidth='1px'
    borderStyle='solid'
    w='100%'
    justify='center'
    align='center'
    p='2.5'
  >
    <Image width='58px' height='58px' src={ `${imagesPath}/document-icon.png` } />
  </Flex>
);
const DocumentReviewIcon = () => (
  <Image width='100%' height='68px' src={ `${imagesPath}/document-review.svg` } />
);

const SuccessDivider = ({ text }: { text: string }) => (
  <Flex justify='center' align='center' position='relative' w='100%'>
    <Divider />
    <Flex
      position='absolute'
      justify='center'
      align='center'
      top='-2.5'
      bg='white'
      gap='1'
      px='4'
    >
      <Check />
      <Text color='text-secondary' size='md' fontWeight='medium'>
        {text}
      </Text>
    </Flex>
  </Flex>
);

const SuccessHeader = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <Flex direction='column' justify='center' align='center'>
    <Flex
      bg='linear-gradient(180deg, #06A36F 0%, #10D0CC 100%)'
      borderRadius='50%'
      p='3'
      mb='4'
    >
      <Check color='white' width='46px' height='46px' />
    </Flex>
    <Text size='5xl' fontWeight='medium'>
      {title}
    </Text>
    <Text size='md' fontWeight='medium'>
      {description}
    </Text>
  </Flex>
);

const Card = ({
  title,
  description,
  image,
}: {
  title: string;
  description: string;
  image: ReactNode;
}) => (
  <Flex
    borderWidth='1px'
    borderStyle='solid'
    borderColor='neutral.200'
    borderRadius='4'
    direction='column'
    justify='start'
    align='start'
    p='4'
    gap='1'
  >
    {image}
    <Text size='2xl' fontWeight='medium'>
      {title}
    </Text>
    <Text color='text-secondary' size='md'>
      {description}
    </Text>
  </Flex>
);

export const Success = ({ isSpainBusiness }: { isSpainBusiness: boolean }) => {
  const { t } = useTranslation();
  const baseTranslation = 'home.slido.success';
  return (
    <Flex direction='column' gap='10'>
      <SuccessHeader
        title={ t(`${baseTranslation}.title`) }
        description={ t(`${baseTranslation}.description`) }
      />
      <SuccessDivider text={ t(`${baseTranslation}.whatsNext`) } />
      <Flex gap='2' className={ cardContainer } justify='space-between'>
        {!isSpainBusiness && (
          <Card
            title={ t(`${baseTranslation}.trackYourMortgage.title`) }
            description={ t(`${baseTranslation}.trackYourMortgage.description`) }
            image={ <DocumentReviewIcon /> }
          />
        )}
        <Card
          title={ t(`${baseTranslation}.uploadMoreDoc.title`) }
          description={ t(`${baseTranslation}.uploadMoreDoc.description`) }
          image={ <DocumentIcon /> }
        />
      </Flex>
    </Flex>
  );
};
