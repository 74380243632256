import { useMutation } from '@tanstack/react-query';
import { kycApi } from '@modules/kyc/api';
import { VaultFormValues } from '@modules/core/pages/Home/form-context';
import { KYCCountry } from '@huspy/forge/types';

const getMonthsLivedCurrent = (field:number | null | undefined) => {
  if (field === null || field === undefined) return null;
  return Math.ceil(field * 12);
};

export const useUpdateVaultStorage = (opportunityId: string) => {
  const { mutateAsync, error, isPending } = useMutation({
    mutationKey: ['update-vault-storage', opportunityId],
    mutationFn: (data: VaultFormValues<KYCCountry>) => {
      const vaultData = data.applicants.map((applicant) => ({
        applicant_id: applicant.applicantId,
        applicant_type: applicant.applicantType,
        personal_information: applicant.personal_information,
        residential_information: {
          ...applicant.residential_information,
          no_of_months_lived_in_current_residence:
          getMonthsLivedCurrent(
            applicant.residential_information.no_of_months_lived_in_current_residence
          ),
        },
        employment_information: applicant.employment_information,
        financial_information: {
          liabilities: [
            ...applicant.autoLoan,
            ...applicant.mortgage,
            ...applicant.personalLoan,
            ...applicant.creditCard
          ],
          incomes: applicant.incomes,
        },
      }));
      return kycApi.updateVaultStorage(opportunityId, vaultData);
    },
    retry: 2,
  });

  return { error, mutateAsync, isPending };
};
