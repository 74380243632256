import { Text } from '@huspy/briks-web';
import { useTranslation } from 'react-i18next';
import { BaseSlido } from '@components/BaseSlido';
import { USER_EVENTS } from '@shared/analytics/events';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { useAuthStore, useBrokerageStore } from '@modules/core/store';
import useGetOpportunityId from '@modules/core/hooks/useGetOpportunityId';
import { SubmitSlidoType } from './types';
import {
  Confirmation, Footer, Success
} from './components';
import { visibleOnDesktop } from './styles.css';

export const SubmitSlido = ({
  isOpen,
  close,
  onSubmit,
  onContinue,
  isLoading,
  isVaultSubmitted,
  numOfMissingFields,
  isSpainBusiness,
}: SubmitSlidoType) => {
  const { t } = useTranslation();
  const { data: brokerage } = useBrokerageStore();
  const { getClientId } = useAuthStore();
  const opportunityId = useGetOpportunityId();

  const handleCancel = () => {
    if (!isVaultSubmitted) {
      trackAmplitudeEvent(USER_EVENTS.FORM_FIELDS.VAULT_FIELDS_SUBMISSION_CANCELED, {
        brokerage_external_id: brokerage?.external_id,
        brokerage_name: brokerage?.name,
        case_id: opportunityId,
        client_id: getClientId(),
      });
    }
    close();
  };

  return (
    <BaseSlido
      title={ (
        <Text size='4xl' className={ visibleOnDesktop }>
          {t(
            `home.slido.submit.${isVaultSubmitted ? 'successTitle' : 'title'}`
          )}
        </Text>
      ) }
      opened={ isOpen }
      close={ handleCancel }
      footer={ (
        <Footer
          isVaultSubmitted={ isVaultSubmitted }
          isLoading={ isLoading }
          onSubmit={ onSubmit }
          onContinue={ onContinue }
          close={ handleCancel }
        />
      ) }
    >
      {isVaultSubmitted ? (
        <Success isSpainBusiness={ isSpainBusiness } />
      ) : (
        <Confirmation numOfMissingFields={ numOfMissingFields } />
      )}
    </BaseSlido>
  );
};
