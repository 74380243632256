import {
  Box, Button, Flex, Text
} from '@huspy/briks-web';
import { useTranslation } from 'react-i18next';
import briksTheme from '@shared/briksTheme';
import { HelpCircle } from '@huspy/briks-icons';
import { useRequestOtp } from '@modules/core/hooks/mutations/useRequestOtp';
import useGetOpportunityId from '@modules/core/hooks/useGetOpportunityId';
import { Tooltip } from '@huspy/forge';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { useAuthStore } from '@modules/core/store';

const OtpToolTip = () => {
  const { t } = useTranslation();
  return (
    <Tooltip
      label={ (
        <Flex direction='column' p='4' gap='2' maxW='350'>
          <Text color='text-primary' size='lg' fontWeight='medium'>
            {t('auth.requestOtp.toolTipTitle')}
          </Text>
          <Text color='text-tertiary' size='md'>
            {t('auth.requestOtp.toolTipDesc')}
          </Text>
        </Flex>
      ) }
      bg='white'
    >
      <Box>
        <HelpCircle
          height='20'
          width='20'
          color={ briksTheme.colors['neutral.500'] }
        />
      </Box>
    </Tooltip>
  );
};

export const RequestOtp = ({
  maskedEmail,
  setSession,
  setIsOtpRequested,
}: {
  maskedEmail: string;
  setSession: (session: string) => void;
  setIsOtpRequested: () => void;
}) => {
  const { t } = useTranslation();
  const oppId = useGetOpportunityId();
  const { mutateAsync, isPending } = useRequestOtp(oppId!);
  const { getClientId } = useAuthStore();

  const handleOnClick = async () => {
    setIsOtpRequested();
    trackAmplitudeEvent(USER_EVENTS.AUTH.VAULT_REQUEST_OTP_CLICKED, { client_id: getClientId() });
    const { session } = await mutateAsync();
    setSession(session);
  };

  return (
    <Flex
      border='1px solid'
      borderColor='neutral.100'
      borderRadius='4'
      p='8'
      gap='4'
      direction='column'
      shadow='shadow-4'
      width='100%'
      maxWidth='500'
    >
      <Text size='5xl' fontWeight='medium'>
        {t('auth.requestOtp.welcome')}
      </Text>
      <Text size='2xl' fontWeight='medium'>
        {t('auth.requestOtp.instructions')}
      </Text>
      <Flex
        bg='blue.100'
        p='4'
        borderRadius='2'
        justify='space-between'
        align='center'
      >
        <Flex direction='column'>
          <Text color='neutral.500' fontWeight='medium' size='sm'>
            {t('auth.requestOtp.emailLabel')}
          </Text>
          <Text color='neutral.500' fontWeight='medium'>
            {maskedEmail}
          </Text>
        </Flex>
        <OtpToolTip />
      </Flex>
      <Flex align='center' gap='1'>
        <Text size='md' color='neutral.500'>
          {t('auth.requestOtp.error')}
        </Text>
      </Flex>
      <Button
        data-testid='request-otp-btn'
        onClick={ handleOnClick }
        loading={ isPending }
        variant={ isPending ? 'secondary' : 'primary' }
      >
        {t('auth.requestOtp.sendButton')}
      </Button>
      <Flex justify='center' align='center' gap='1'>
        <Text color='neutral.500' size='sm'>
          {t('auth.poweredBy')}
        </Text>
        <Text color='neutral.500' fontWeight='semibold'>
          Client Vault
        </Text>
      </Flex>
    </Flex>
  );
};
