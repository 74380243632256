import { kycApi } from '@modules/kyc/api';
import { VaultException } from '@shared/exceptions';
import { useQuery } from '@tanstack/react-query';
import { useCreateVaultStorage } from '@modules/kyc/hooks/mutations/useCreateVaultStorage';

export const useGetVaultStorage = (
  opportunityId: string | undefined,
  enabled: boolean
) => {
  const { mutateAsync } = useCreateVaultStorage(opportunityId!);
  const { data, isLoading, error } = useQuery({
    queryKey: ['vault-storage', opportunityId],
    queryFn: async () => {
      /* eslint-disable @typescript-eslint/return-await */
      try {
        return await kycApi.getVaultStorage(opportunityId!);
      } catch (_error) {
        if ((_error as VaultException).statusCode === 404) {
          await mutateAsync();
          return await kycApi.getVaultStorage(opportunityId!);
        }
        throw _error;
      }
      /* eslint-enable @typescript-eslint/return-await */
    },
    enabled,
    retry: false,
  });

  return {
    data,
    isLoading,
    error,
  };
};
