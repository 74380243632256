import {
  Box, Flex, Text
} from '@huspy/briks-web';
import { Image } from '@mantine/core';
import { imagesPath } from '@shared/css.const';
import briksTheme from '@shared/briksTheme';
import { t } from 'i18next';
import {
  BANK_APPLICATION_STATUS,
  BankApplicationStatusType,
  OPPORTUNITY_STATUS,
  OpportunityStatusType,
} from '@modules/core/api/types';
import { Header } from './Header';

const baseTranslation = 'mortgageTimeline.mortgageStatusCard';

interface StatusDetails {
  header: string;
  title: string;
  description: string;
  textColor: string;
  image: string;
}
const statusMapper: Partial<
Record<OpportunityStatusType | BankApplicationStatusType, StatusDetails>
> = {
  [OPPORTUNITY_STATUS.new]: {
    header: t(`${baseTranslation}.new.header`),
    title: t(`${baseTranslation}.new.title`),
    description: t(`${baseTranslation}.new.description`),
    textColor: briksTheme.colors['neutral.500'],
    image: `${imagesPath}/new-case.svg`,
  },
  [OPPORTUNITY_STATUS.onHold]: {
    header: t(`${baseTranslation}.onHold.header`),
    title: t(`${baseTranslation}.onHold.title`),
    description: t(`${baseTranslation}.onHold.description`),
    textColor: briksTheme.colors['warning.500'],
    image: `${imagesPath}/on-hold-timeline.svg`,
  },
  [OPPORTUNITY_STATUS.lost]: {
    header: t(`${baseTranslation}.notProceeding.header`),
    title: t(`${baseTranslation}.notProceeding.title`),
    description: t(`${baseTranslation}.notProceeding.description`),
    textColor: briksTheme.colors['error.500'],
    image: `${imagesPath}/no-proceeding-timeline.svg`,
  },
  [OPPORTUNITY_STATUS.notAvailable]: {
    header: t(`${baseTranslation}.notAvailable.header`),
    title: t(`${baseTranslation}.notAvailable.title`),
    description: t(`${baseTranslation}.notAvailable.description`),
    textColor: briksTheme.colors['neutral.500'],
    image: `${imagesPath}/error-timeline.svg`,
  },
  [BANK_APPLICATION_STATUS.onHold]: {
    header: t(`${baseTranslation}.onHold.header`),
    title: t(`${baseTranslation}.onHold.title`),
    description: t(`${baseTranslation}.onHold.description`),
    textColor: briksTheme.colors['warning.500'],
    image: `${imagesPath}/on-hold-timeline.svg`,
  },
  [BANK_APPLICATION_STATUS.notProceeding]: {
    header: t(`${baseTranslation}.notProceeding.header`),
    title: t(`${baseTranslation}.notProceeding.title`),
    description: t(`${baseTranslation}.notProceeding.description`),
    textColor: briksTheme.colors['error.500'],
    image: `${imagesPath}/no-proceeding-timeline.svg`,
  },
  [BANK_APPLICATION_STATUS.declined]: {
    header: t(`${baseTranslation}.notProceeding.header`),
    title: t(`${baseTranslation}.notProceeding.title`),
    description: t(`${baseTranslation}.notProceeding.description`),
    textColor: briksTheme.colors['error.500'],
    image: `${imagesPath}/no-proceeding-timeline.svg`,
  },
};

export const Disclaimer = ({
  title,
  description,
  textColor,
}: {
  title: string;
  description: string;
  textColor: string;
}) => (
  <Flex direction='column' align='center'>
    <Flex gap='2' align='center' my='3'>
      <Text
        size='3xl'
        letterSpacing='xl'
        fontWeight='medium'
        style={ { color: textColor } }
      >
        {title}
      </Text>
    </Flex>
    <Text size='2xl' color='text-secondary' textAlign='center'>
      {description}
    </Text>
  </Flex>
);
export const MortgageStatusCard = ({ status }: {
  status: OpportunityStatusType | BankApplicationStatusType;
}) => {
  const mapper = statusMapper[status] || statusMapper[OPPORTUNITY_STATUS.notAvailable]!;

  return (
    <>
      <Header title={ mapper.header } />
      <Flex
        direction='column'
        gap='2'
        align='center'
        mt='8'
        bg='white'
        borderRadius='4'
        borderWidth='1px'
        borderStyle='solid'
        borderColor='neutral.200'
        p='4'
      >
        <Box w='100%'>
          <Image width='100%' height='100%' src={ mapper.image } />
        </Box>
        <Disclaimer
          title={ mapper.title }
          description={ mapper.description }
          textColor={ mapper.textColor }
        />
      </Flex>
    </>
  );
};
