/* eslint-disable unicorn/prefer-at */
import { Flex } from '@huspy/briks-web';
import { Timeline } from '@mantine/core';
import { VAULT_TAB, VaultTab } from '@modules/core/pages/Home/types';
import { scrollToError as scrollTo } from '@shared/utils';
import { useEffect } from 'react';
import { useGetMortgageTimeline } from '@modules/core/hooks/queries/useGetMortgageTimeline';
import useGetOpportunityId from '@modules/core/hooks/useGetOpportunityId';
import {
  BANK_APPLICATION_STATUS,
  BANK_APPLICATION_SUB_STATUS,
  BANK_SUBMISSION_TYPE,
  BankApplicationStatusType,
  BankApplicationSubStatusType,
  OPPORTUNITY_STATUS,
  OpportunityStatusType,
  StatusHistory,
} from '@modules/core/api/types';
import { StatusIcon } from './components/StatusIcon';
import { TimelineCard } from './components/TimelineCard';
import { mortgageTimeline } from './mortgageTimeline';
import { Header } from './components/Header';
import { MortgageStatusCard } from './components/MortgageStatusCard';

const removeStatusHistoryDuplicates = (timeline?: StatusHistory[]) => {
  const items: {
    status: BankApplicationStatusType;
    sub_status?: string;
    timestamp: string;
  }[] = [];
  timeline?.forEach((event) => {
    const idx = items.findIndex((val) => val.status === event.status);
    if (idx > -1) {
      items[idx]!.timestamp = event.timestamp;
    } else {
      items.push(event);
    }
  });

  return items;
};

const BankApplicationTimeline = ({
  statusHistory,
  activeVaultTab,
  mostRecentStatus,
}: {
  statusHistory: StatusHistory[];
  activeVaultTab: VaultTab;
  mostRecentStatus: BankApplicationStatusType | BankApplicationSubStatusType;
}) => {
  const currentStep = mortgageTimeline.find((step) =>
    step.statuses?.includes(mostRecentStatus));

  const currentStepOrder = currentStep?.order!;

  const filteredStatusHistory = removeStatusHistoryDuplicates(statusHistory);

  let latestTimestamp = filteredStatusHistory[0]?.timestamp!;

  const rejectedStatus:
  | BankApplicationStatusType[]
  | BankApplicationSubStatusType[] = [
    BANK_APPLICATION_STATUS.notProceeding,
    BANK_APPLICATION_STATUS.onHold,
    BANK_APPLICATION_STATUS.declined
  ];

  const draftStatues: BankApplicationStatusType[] = [
    BANK_APPLICATION_STATUS.draft,
    BANK_APPLICATION_STATUS.proposal,
    BANK_APPLICATION_STATUS.docsCollection
  ];

  useEffect(() => {
    if (currentStepOrder && activeVaultTab === 'mortgage-timeline') {
      scrollTo({ [`step-${currentStepOrder}`]: '' });
    }
  }, [currentStepOrder, activeVaultTab]);

  if (rejectedStatus.includes(mostRecentStatus as BankApplicationStatusType)) {
    return (
      <MortgageStatusCard
        status={ mostRecentStatus as BankApplicationStatusType }
      />
    );
  }

  if (draftStatues.includes(mostRecentStatus as BankApplicationStatusType)) {
    return <MortgageStatusCard status={ OPPORTUNITY_STATUS.new } />;
  }

  if (!currentStep) {
    return <MortgageStatusCard status={ OPPORTUNITY_STATUS.notAvailable } />;
  }

  return (
    <>
      <Header title={ currentStep.title } />
      <Flex
        justifyContent='center'
        align='center'
        bg='white'
        borderRadius='4'
        p='8'
      >
        <Timeline bulletSize={ 24 } lineWidth={ 4 } pos='relative' top='35px'>
          {mortgageTimeline.map((step, index) => {
            const isCompleted = currentStepOrder > step.order;
            const isCurrentStep = currentStepOrder === step.order;
            const isLast = index === mortgageTimeline.length - 1;
            const matchingStatus = filteredStatusHistory.find((status) =>
              step.statuses.includes(status.status));

            if (matchingStatus) {
              latestTimestamp = matchingStatus.timestamp;
            }

            return (
              <Timeline.Item
                key={ step.order }
                bullet={ isCompleted || isCurrentStep || isLast ? (
                  <StatusIcon
                    isCompleted={ isCompleted }
                    isCurrent={ isCurrentStep }
                    isLast={ isLast }
                  />
                ) : undefined }
              >
                <TimelineCard
                  id={ `step-${step.order}` }
                  title={ step.title }
                  description={ step.description }
                  isCompleted={ isCompleted }
                  isCurrent={ isCurrentStep }
                  date={ latestTimestamp }
                />
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Flex>
    </>
  );
};

const getMostRecentStatus = (
  status: BankApplicationStatusType,
  subStatus: BankApplicationSubStatusType | undefined | null
) => {
  const folSubStatuses: BankApplicationSubStatusType[] = [
    BANK_APPLICATION_SUB_STATUS.received,
    BANK_APPLICATION_SUB_STATUS.requested,
    BANK_APPLICATION_SUB_STATUS.queries,
    BANK_APPLICATION_SUB_STATUS.queriesResponded
  ];
  if (
    status === BANK_APPLICATION_STATUS.fol
    && subStatus
    && folSubStatuses.includes(subStatus)
  ) {
    return subStatus;
  }

  return status;
};

export const MortgageTimeline = ({ activeVaultTab }: {
  activeVaultTab: VaultTab;
}) => {
  const oppId = useGetOpportunityId();
  const { data, isLoading, error } = useGetMortgageTimeline(oppId);
  const rejectedStatus: OpportunityStatusType[] = [
    OPPORTUNITY_STATUS.onHold,
    OPPORTUNITY_STATUS.lost
  ];

  const newStatus: OpportunityStatusType[] = [
    OPPORTUNITY_STATUS.new,
    OPPORTUNITY_STATUS.draft
  ];

  if (activeVaultTab !== VAULT_TAB.MORTGAGE_TIMELINE) {
    return <></>;
  }

  if (isLoading) {
    return <></>;
  }
  if (error || !data) {
    return <MortgageStatusCard status={ OPPORTUNITY_STATUS.notAvailable } />;
  }

  const { bank_applications, status } = data.opportunity;
  const statusHistory = bank_applications[0]?.status_history;
  const isDirectToBank = bank_applications[0]?.bank_submission_channel_type
    === BANK_SUBMISSION_TYPE.DIRECT_TO_BANK;

  const isNewOpportunity = bank_applications.length === 0 && newStatus.includes(status);
  const hasErrors = (bank_applications.length === 0
      && status === OPPORTUNITY_STATUS.inProgress)
    || isDirectToBank;

  const isRejected = rejectedStatus.includes(status);

  if (hasErrors) {
    return <MortgageStatusCard status={ OPPORTUNITY_STATUS.notAvailable } />;
  }

  if (isNewOpportunity) {
    return <MortgageStatusCard status={ OPPORTUNITY_STATUS.new } />;
  }

  if (isRejected) {
    return <MortgageStatusCard status={ status } />;
  }

  const mostRecentStatus = getMostRecentStatus(
    bank_applications[0]?.status!,
    bank_applications[0]?.sub_status
  );
  return (
    <BankApplicationTimeline
      statusHistory={ statusHistory! }
      activeVaultTab={ activeVaultTab }
      mostRecentStatus={ mostRecentStatus }
    />
  );
};
