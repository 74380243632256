import { AlertTriangle, CheckCircle } from '@huspy/briks-icons';
import {
  Flex, Spinner, Text
} from '@huspy/briks-web';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '@modules/core/store';
import briksTheme from '@shared/briksTheme';

export const AutoSaveLoader = ({
  isLoading,
  hasError,
  isVaultSubmitted,
}: {
  isLoading: boolean;
  hasError: boolean;
  isVaultSubmitted: boolean;
}) => {
  const { t } = useTranslation();
  const { getIsAutoSave } = useAuthStore();
  const isAutoSaveOn = getIsAutoSave();

  if (!isAutoSaveOn) {
    return <></>;
  }

  if (isVaultSubmitted) {
    return (
      <Flex align='center' gap='1'>
        <CheckCircle color='green' />
        <Text color='text-secondary' size='sm'>
          {t('home.autoSaveLoader.submitted')}
        </Text>
      </Flex>
    );
  }

  if (isLoading) {
    return (
      <Flex align='center' gap='1'>
        <Spinner />
        <Text color='text-secondary' size='sm'>
          {t('home.autoSaveLoader.progressSaving')}
        </Text>
      </Flex>
    );
  }
  if (hasError) {
    return (
      <Flex align='center' gap='1'>
        <AlertTriangle color={ briksTheme.colors['warning.400'] } />
        <Text color='text-secondary' size='sm'>
          {t('home.autoSaveLoader.progressFailed')}
        </Text>
      </Flex>
    );
  }
  return (
    <Flex align='center' gap='1'>
      <CheckCircle color='green' />
      <Text color='text-secondary' size='sm'>
        {t('home.autoSaveLoader.progressSaved')}
      </Text>
    </Flex>
  );
};
