import http from '@shared/http';
import { API_V1_URL, API_V2_URL } from '@shared/const';
import {
  DirectFeatureFlagResponse, MortgageTimeline, PublicVaultData, VaultOverview
} from './types';

export const coreApi = {
  login: (password: string, opportunityId: string) =>
    http.post<{
      access_token: string;
      expires_in: number;
      token_type: string;
    }>(
      `${API_V1_URL}/identity/vault/auth`,
      {
        password,
        vault_id: opportunityId,
      },
      {},
      undefined,
      false
    ),
  requestOtp: (opportunityId: string, isResend: boolean) =>
    http.post<{ session: string }>(
      `${API_V2_URL}/vaults/auth/request_otp`,
      {
        opportunity_id: opportunityId,
        is_resend: isResend,
      },
      {},
      undefined,
      false
    ),
  verifyOtp: (opportunityId: string, session: string, otp: string) =>
    http.post<{ token: string }>(
      `${API_V2_URL}/vaults/auth/verify_otp`,
      {
        opportunity_id: opportunityId,
        session,
        otp,
      },
      {},
      undefined,
      false
    ),
  getVaultOverview: (opportunityId: string) =>
    http.get<VaultOverview>(`${API_V2_URL}/vaults/${opportunityId}/overview`),
  getMortgageTimeline: (opportunityId: string) =>
    http.get<{ opportunity: MortgageTimeline }>(`${API_V2_URL}/vaults/${opportunityId}/mortgage-timeline`),
  getPublicVaultData: (opportunityId: string) =>
    http.get<PublicVaultData>(
      `${API_V2_URL}/vaults/${opportunityId}/public`,
      {},
      {},
      undefined,
      false
    ),
  getFeatureFlagDirectly: (flagKey: string, user_id?: string) =>
    http.get<DirectFeatureFlagResponse>(
      AMPLITUDE_FEATURE_FLAGS_API_URL,
      {
        flag_key: flagKey,
        user_id,
        t: Date.now(),
      },
      {
        Authorization: `Api-Key ${AMPLITUDE_FEATURE_FLAGS_API_KEY}`,
        Accept: 'application/json;charset=utf-8',
      }
    ),
};
