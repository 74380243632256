import { Flex, Text } from '@huspy/briks-web';
import { t } from 'i18next';
import { headerStyles, headerTextStyles } from './styles.css';

export const Header = ({ title }:{ title: string }) => (
  <Flex
    className={ headerStyles }
    gap='2'
    align='center'
    bg='blue.50'
    my='3'
  >
    <Text
      size='3xl'
      letterSpacing='xl'
    >
      {t('mortgageTimeline.header')}
    </Text>
    <Text size='6xl' color='neutral.300' className={ headerTextStyles }>
      &middot;
    </Text>
    <Text
      className={ headerTextStyles }
      size='3xl'
      color='neutral.600'
      letterSpacing='xl'
    >
      {title}
    </Text>
  </Flex>
);
