import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { coreApi } from '@modules/core/api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { useAuthStore } from '@modules/core/store';
import { USER_EVENTS } from '@shared/analytics/events';

export const useVerifyOtp = (opportunityId: string) => {
  const { t } = useTranslation();
  const { saveTokenToSessionStorage, getClientId } = useAuthStore();
  const navigate = useNavigate();
  const {
    mutateAsync, error, isPending, reset,
  } = useMutation({
    mutationKey: ['verify-otp'],
    mutationFn: ({ otp, session }: { otp: string; session: string }) =>
      coreApi.verifyOtp(opportunityId, session, otp),
    onSuccess: ({ token }) => {
      if (token) {
        saveTokenToSessionStorage(token);
        toast('info', { message: t('login.notifications.loginSuccessful') });
        trackAmplitudeEvent(
          USER_EVENTS.AUTH.VAULT_OTP_LOGIN_COMPLETED,
          { client_id: getClientId() }
        );
        navigate(`/${opportunityId}`);
      }
    },
    onError: (err) => {
      if (err.statusCode === 500) {
        toast('error', { message: 'Something went wrong' });
      }
    },
  });

  return {
    error,
    mutateAsync,
    isPending,
    reset,
  };
};
