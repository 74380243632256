export default {
  VAULT_FIELDS_SAVE_ATTEMPTED: 'Vault Fields Save Attempted',
  VAULT_FIELDS_SAVE_COMPLETED: 'Vault Fields Save Completed',
  VAULT_FIELDS_SAVE_FAILED: 'Vault Fields Save Failed',
  VAULT_FIELDS_VALIDATION_RAISED: 'Vault Field Validation Raised',
  VAULT_FIELDS_SUBMISSION_ATTEMPTED: 'Vault Fields Submission Attempted',
  VAULT_FIELDS_SUBMISSION_CANCELED: 'Vault Fields Submission Canceled',
  VAULT_FIELDS_SUBMISSION_COMPLETED: 'Vault Fields Submission Completed',
  VAULT_FIELDS_SUBMISSION_FAILED: 'Vault Fields Submission Failed',
};
