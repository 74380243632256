import { createFormContext } from '@mantine/form';
import {
  ApplicantType,
  EmploymentKYCData, Income, KYCCountry, Liability, PersonalKYCData, ResidencyKYCData
} from '@huspy/forge/types';

export interface VaultFormValues<TCountry extends KYCCountry> {
  applicants: {
    applicantId: string;
    applicantType: ApplicantType;
    personal_information: PersonalKYCData<TCountry>;
    residential_information: ResidencyKYCData;
    incomes: Income[];
    employment_information: EmploymentKYCData<TCountry>;
    creditCard: Liability[];
    personalLoan: Liability[];
    autoLoan: Liability[];
    mortgage: Liability[];
  }[]
}

export const [
  VaultFormProvider,
  useVaultFormContext,
  useVaultsForm
] = createFormContext<VaultFormValues<KYCCountry>>();
