import { useEffect, useRef } from 'react';
import { UseFormReturnType } from '@mantine/form';
import { useUpdateVaultStorage } from '@modules/kyc/hooks/mutations/useUpdateVaultStorage';
import { KYCCountry } from '@huspy/forge/types';
import { useAuthStore } from '@modules/core/store';
import { VaultFormValues } from '../pages/Home/form-context';

export function useAutoSave(
  form: UseFormReturnType<VaultFormValues<'AE'>>,
  opportunityId: string,
  updateFieldsCount: (formValues: VaultFormValues<'AE'>) => void,
  isVaultSubmitted: boolean = false
) {
  const previousValuesRef = useRef(form.getValues());
  const { mutateAsync, isPending, error } = useUpdateVaultStorage(opportunityId);
  const { getIsAutoSave } = useAuthStore();
  const isAutoSaveOn = getIsAutoSave();

  useEffect(() => {
    if (isAutoSaveOn && !isVaultSubmitted) {
      const intervalId = setInterval(async () => {
        const currentValues = form.getValues();
        if (
          JSON.stringify(previousValuesRef.current)
          !== JSON.stringify(currentValues)
        ) {
          updateFieldsCount(currentValues);
          previousValuesRef.current = currentValues;
          await mutateAsync(currentValues as VaultFormValues<KYCCountry>);
          form.resetDirty();
        }
      }, 3000);
      return () => clearInterval(intervalId);
    }
  }, [form, mutateAsync, isAutoSaveOn, isVaultSubmitted, updateFieldsCount]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (form.isDirty() && isAutoSaveOn && !isVaultSubmitted) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [form, isAutoSaveOn, isVaultSubmitted]);

  return { isAutoSaveLoading: isPending, autoSaveHasError: !!error };
}
