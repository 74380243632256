import React, { Dispatch, SetStateAction } from 'react';
import { AppShell } from '@mantine/core';
import '@mantine/core/styles/AppShell.css';
import {
  Box, Flex, Text
} from '@huspy/briks-web';
import { useTranslation } from 'react-i18next';
import { VaultTab } from '../../types';
import { contentWrapper } from './styles.css';
import {
  MobileNavbarTabs,
  Header,
  Footer,
  DesktopNavbarTabs,
} from './components';

interface VaultAppShellProps {
  activeVaultTab: VaultTab;
  setActiveVaultTab: Dispatch<SetStateAction<VaultTab>>;
  children: React.ReactNode;
  formDescription: string;
  isFormCompleted: boolean;
  isAutoSaveLoading: boolean;
  isSpainBusiness: boolean;
  autoSaveHasError: boolean;
  isSubmitLoading: boolean;
  isVaultSubmitted: boolean;
}

export const VaultAppShell: React.FC<VaultAppShellProps> = ({
  activeVaultTab,
  setActiveVaultTab,
  children,
  formDescription,
  isFormCompleted,
  autoSaveHasError,
  isAutoSaveLoading,
  isSpainBusiness,
  isSubmitLoading,
  isVaultSubmitted,
}) => {
  const { t } = useTranslation();

  return (
    <AppShell
      header={ { height: 84 } }
      navbar={ {
        width: { base: 300, md: 300, lg: 367 },
        breakpoint: '993',
        collapsed: { mobile: true },
      } }
    >
      <AppShell.Header>
        <Header
          logoutLabel={ t('home.header.logout') }
          isAutoSaveLoading={ isAutoSaveLoading }
          autoSaveHasError={ autoSaveHasError }
          isVaultSubmitted={ isVaultSubmitted }
        />
        <MobileNavbarTabs
          activeTab={ activeVaultTab }
          onTabChange={ setActiveVaultTab }
          isSpainBusiness={ isSpainBusiness }
        />
      </AppShell.Header>
      <AppShell.Navbar>
        <Flex p='32px 24px 0 48px' direction='column' gap='10'>
          <Flex direction='column' gap='1'>
            <Text size='2xl' fontWeight='medium' letterSpacing='xl'>
              {t('home.navbar.title')}
            </Text>
            <Text size='md' color='neutral.500' letterSpacing='xl'>
              {t('home.navbar.subtitle')}
            </Text>
          </Flex>
          <DesktopNavbarTabs
            activeTab={ activeVaultTab }
            onTabChange={ setActiveVaultTab }
            formDescription={ formDescription }
            isFormCompleted={ isFormCompleted }
            isVaultSubmitted={ isVaultSubmitted }
            isSpainBusiness={ isSpainBusiness }
          />
        </Flex>
      </AppShell.Navbar>
      <AppShell.Main bg='#F9FAFB'>
        <Box className={ contentWrapper }>{children}</Box>
      </AppShell.Main>
      <AppShell.Footer
        bg='rgba(256,256, 256, 80%)'
        style={ { backdropFilter: 'blur(8px)' } }
      >
        <Footer
          isSubmitLoading={ isSubmitLoading }
          isAutoSaveLoading={ isAutoSaveLoading }
          activeVaultTab={ activeVaultTab }
          autoSaveHasError={ autoSaveHasError }
          isVaultSubmitted={ isVaultSubmitted }
        />
      </AppShell.Footer>
    </AppShell>
  );
};
